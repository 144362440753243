import { Button, Tooltip, TooltipContent, TooltipTrigger } from "ui";
import { Image } from "@phosphor-icons/react";
import React, { useState } from "react";
import { Text } from "~/components/Text";
import UploadImageModal from "~/components/Modal/UploadImageModal";
import { cn } from "~/lib/utils";
import { useAnalytics } from "~/context/AnalyticsProvider";

interface Props {
  onUploadFileSuccess(fileId: string): Promise<void>;

  hasUploadedFile: boolean;
}

const ImageUploadButton = ({ onUploadFileSuccess, hasUploadedFile }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { log } = useAnalytics();

  const handleOnClick = () => {
    log({
      type: "image_upload_modal_opened",
    });
    setIsModalOpen(true);
  };

  return (
    <>
      <Tooltip>
        <TooltipTrigger className="cursor-pointer" asChild>
          <span className="relative">
            <Button
              size="icon"
              variant="ghost"
              type="button"
              className="text-icons-50 hover:text-primary"
              onClick={handleOnClick}
              data-testid="chat-input-image-button"
              disabled={hasUploadedFile}
            >
              <Image className="h-6 w-6" />
            </Button>
            {hasUploadedFile && (
              <div
                className={cn(
                  "h-6 w-6 absolute z-10 top-0 left-5 border-[3px] border-background  rounded-full bg-action-primary opacity-0 transition-opacity",
                  hasUploadedFile && "opacity-100",
                )}
                data-testid="file-attached"
              >
                <span className="text-[12px] text-white absolute z-10 top-[-1px] left-[5px]">
                  1
                </span>
              </div>
            )}
          </span>
        </TooltipTrigger>
        <TooltipContent data-testid="chat-input-image-button-tooltip">
          <Text
            id={
              hasUploadedFile
                ? "chats.tooltips.file-active"
                : "chats.tooltips.image-upload"
            }
          />
        </TooltipContent>
      </Tooltip>

      <UploadImageModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onUploadFileSuccess={onUploadFileSuccess}
      />
    </>
  );
};

export default ImageUploadButton;
