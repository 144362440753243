import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { v4 } from "uuid";

interface Notification {
  id: string;
  message: string;
  type?: "success" | "error";
  timeout: number;
  link?: { to: string; text: string };
}

interface NotificationsContextProps {
  notifications: Notification[];

  addNotification(
    message: string,
    type: Notification["type"],
    link?: { to: string; text: string },
    timeout?: number,
  ): void;

  clearNotification(id: string): void;
}

export const NotificationsContext = createContext<NotificationsContextProps>(
  undefined as never,
);

export const useNotifications = () => useContext(NotificationsContext);

export function NotificationsProvider({ children }: PropsWithChildren) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = useCallback(
    (
      message: string,
      type: Notification["type"],
      link?: { to: string; text: string },
      timeout = 4000,
    ) => {
      const id = v4();
      setNotifications((prev) => [
        ...prev,
        {
          id,
          type,
          message,
          link,
          timeout,
        },
      ]);

      setTimeout(() => {
        setNotifications((prev) =>
          prev.filter((notification) => notification.id !== id),
        );
      }, timeout);
    },
    [],
  );

  const clearNotification = useCallback((id: string) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id),
    );
  }, []);

  const value = useMemo(() => {
    return {
      notifications,
      addNotification,
      clearNotification,
    };
  }, [notifications, addNotification, clearNotification]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
}
