import { Button, Header } from "ui";
import React from "react";
import { SidebarSimple } from "@phosphor-icons/react";
import { cn } from "~/lib/utils";
import { AccountMenu } from "~/components/AccountMenu/AccountMenu";
import { useChat } from "~/context/ChatContext";
import { getChatTitle } from "~/utils/chat";
import { ChatContextMenu } from "~/components/ChatHeader/ChatContextMenu";
import { useLayout } from "~/context/LayoutContext";
import { useAnalytics } from "~/context/AnalyticsProvider";

interface ChatHeaderProps {
  className?: string;
}

export function ChatHeader({ className }: ChatHeaderProps) {
  const { showSidebar, toggleSidebar } = useLayout();
  const { log } = useAnalytics();
  const chat = useChat();

  const onToggleSidebar = () => {
    log({
      type: "sidebar_toggle_clicked",
      payload: {
        closed: !showSidebar,
      },
    });

    toggleSidebar();
  };

  const title = getChatTitle(chat?.chat) || "New chat";

  return (
    <Header
      className={cn(
        "gap-4 grid grid-cols-[auto,1fr,auto] items-center",
        className,
      )}
    >
      <Button
        size="icon"
        variant="ghost"
        onClick={onToggleSidebar}
        className={cn(showSidebar && "opacity-0 w-0 transition-all")}
        data-testid="chat-header-sidebar-toggle"
      >
        <SidebarSimple className="h-8 w-8 text-icons-50" />
      </Button>

      <div className="grid grid-cols-[1fr,auto] gap-2 mr-auto items-center">
        <div className="text-xl font-medium truncate">{title}</div>

        <div>
          {chat && (
            <ChatContextMenu
              onRename={chat.onRename}
              onDelete={chat.onDelete}
            />
          )}
        </div>
      </div>

      <AccountMenu />
    </Header>
  );
}
