import React, { PropsWithChildren } from "react";
import { cn } from "./utils";

interface HeaderProps {
  className?: string;
}

export function Header({
  children,
  className,
}: PropsWithChildren<HeaderProps>) {
  return (
    <div
      className={cn(
        "p-4 shadow-header dark:shadow-none dark:border-b dark:border-b-white/10 bg-background",
        className,
      )}
    >
      {children}
    </div>
  );
}
