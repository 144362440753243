import React, { useMemo } from "react";
import { cn } from "./utils";

interface ProgressProps {
  className?: string;
  progress?: number;
}

export function Progress({ progress = 0, className }: ProgressProps) {
  const radius = 44;

  const { strokeDasharray, strokeDashoffset } = useMemo(() => {
    const percent = (100 - Math.min(progress, 100)) / 100;
    const strokeDasharray = 2 * Math.PI * radius;
    const strokeDashoffset = strokeDasharray * percent;

    return {
      strokeDasharray,
      strokeDashoffset,
    };
  }, [progress]);

  return (
    <svg className={cn("-rotate-90", className)} viewBox="0 0 100 100">
      <circle
        className="stroke-border"
        fill="none"
        cx="50"
        cy="50"
        r={radius}
        strokeWidth={10}
      />

      <circle
        className="stroke-action-primary transition-all"
        fill="none"
        cx="50"
        cy="50"
        r={radius}
        strokeLinecap="round"
        strokeWidth={10}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
      />
    </svg>
  );
}
