import { useText } from "@noa/locales";
import { doc, DocumentReference } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { UserProfile } from "@noa/types/UserProfile";
import { useCallback } from "react";
import { useNotifications } from "~/context/NotificationsContext";
import { db, setDoc } from "~/integrations/firebase/firestore";

export const useProfile = (userId: string) => {
  const { addNotification } = useNotifications();
  const text = useText();

  const reference = doc(
    db,
    "userProfiles",
    userId,
  ) as DocumentReference<UserProfile>;

  const [profile, loading, error, snapshot] = useDocumentData(reference);

  const updateUserProfile = useCallback(
    async (userProfile: UserProfile) => {
      const exists = !snapshot?.exists();

      try {
        await setDoc(reference, userProfile);

        const message = exists
          ? "profile.create.success.create"
          : "profile.create.success.create";

        addNotification(text(message), "success");
      } catch (e) {
        console.error(e);
        addNotification(text("profile.create.error"), "error");
      }
    },
    [addNotification, reference, snapshot, text],
  );

  return { profile, loading, error, updateUserProfile };
};
