import { Timestamp } from "firebase/firestore";
import { Model, Platform } from "./ModelConfig";

export interface ChatFile {
  updatedAt: Timestamp;
}

export interface Chat {
  id: string;
  status: ChatStatus;
  updatedAt: Timestamp;
  userId: string;
  messages: ChatMessage[];
  files: Record<string, ChatFile> | null;
  organisationId: string;
  deleted: boolean;
  error: ChatError | null;
  title?: string;
  taskId?: string;
}

export interface ChatMessage {
  id: string;
  previousMessageId: string | null;
  updatedAt: Timestamp;
  role: Role;
  content: string | null;
  errorMessage: string | null;
  platform: Platform | null;
  model: Model | null;
  idempotencyKey: string | null;
  rating?: Rating; // Add rating field
  files?: string[];
}

export enum RatingType {
  LIKE = 1,
  DISLIKE = -1,
}

export interface Rating {
  updatedAt: Timestamp;
  value: RatingType;
  feedbackText: string | null;
}

export enum Role {
  USER = "USER",
  AI = "AI",
  SYSTEM = "SYSTEM",
}

export enum ChatStatus {
  REQUESTED = "REQUESTED",
  READY = "READY",
  REGENERATE = "REGENERATE",
  STREAMING = "STREAMING",
  ERROR = "ERROR",
}

export interface ChatError {
  errorCode: string;
  version: string;
}

export enum ChatErrorCode {
  NONE = "",
  ERR_OPENAI = "100",
  ERR_OPENAI_API = "101",
  ERR_OPENAI_TIMEOUT = "102",
  ERR_OPENAI_API_CONN = "103",
  ERR_OPENAI_INVALID_REQ = "104",
  ERR_OPENAI_AUTH = "105",
  ERR_OPENAI_PERMISSION = "106",
  ERR_OPENAI_RATE_LIMIT = "107",
  ERR_OPENAI_SERVICE_UNAVAILABLE = "108",
  ERR_OPENAI_TRY_AGAIN = "109",
  ERR_OPENAI_CONTEXT_LENGTH_EXCEEDED = "110",
  ERR_PINECONE = "200",
  ERR_PINECONE_AUTH = "201",
  ERR_INVALID_EVENT_PAYLOAD = "400",
}
