import { memo } from "react";
import { Text as LocaleText } from "@noa/locales";
import { Locale, locales, LocaleStrings } from "~/locales";

interface Props {
  id: LocaleStrings;
  locale?: Locale;
  multiline?: boolean;
  className?: string;
}

export function text(id: LocaleStrings, locale?: Locale) {
  const strings = locales[locale || "en"];

  if (!strings) {
    return id;
  }

  const value = strings[id];

  return value || id;
}

export const Text = memo(function Text({
  id,
  locale = "en",
  multiline,
  className,
}: Props) {
  const strings = locales[locale];

  if (!strings) {
    return <div>{id}</div>;
  }

  return (
    <LocaleText
      id={id}
      className={className}
      multiline={multiline}
      locale={locale}
    />
  );
});
