import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Analytics,
  initializeAnalytics,
  logEvent,
  setAnalyticsCollectionEnabled,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { entries, fromPairs, snakeCase } from "lodash";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation } from "react-router-dom";
import { AnalyticsEvent } from "~/types/AnalyticsEvents";
import { app } from "~/integrations/firebase/app";
import { auth } from "~/integrations/firebase/auth";

interface ApplicationContext {
  initialised: boolean;

  log(event: AnalyticsEvent): void;

  setUser(id?: string | null): void;

  setUserDetails(properties: UserProperties): void;
}

interface UserProperties {
  organisationId?: string;
  organisationName?: string;
}

export const AnalyticsContext = createContext<ApplicationContext>(
  undefined as never,
);

export const useAnalytics = () => useContext(AnalyticsContext);

function initialiseAnalytics() {
  const analytics = initializeAnalytics(app, {
    config: {
      // Handle page views manually
      send_page_view: false,
    },
  });

  // Set to false initially to avoid sending automatic events without a user id
  setAnalyticsCollectionEnabled(analytics, false);

  return analytics;
}

function resetAnalytics(analytics?: Analytics) {
  if (!analytics) {
    return;
  }

  setUserId(analytics, null);
  setUserProperties(analytics, { organisation_id: null });
}

export function AnalyticsProvider({ children }: PropsWithChildren) {
  const [analytics, setAnalytics] = useState<Analytics>();

  const location = useLocation();

  useEffect(() => {
    if (!analytics) {
      return;
    }

    // Re-enable analytics once the client is initialised with a user id
    setAnalyticsCollectionEnabled(analytics, true);

    // Log the initial page view that would have been missed due to disabled analytics
    logEvent(analytics, "page_view");
  }, [analytics]);

  useEffect(() => {
    if (!analytics) {
      return;
    }

    logEvent(analytics, "page_view");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const [authUser, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading) {
      return;
    }

    // initialize analytics once auth state is loaded
    const client = analytics ?? initialiseAnalytics();

    if (!authUser?.uid) {
      resetAnalytics(client);
    } else {
      setUserId(client, authUser.uid);
    }

    setAnalytics(client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, authUser?.uid]);

  const log = useCallback(
    (event: AnalyticsEvent) => {
      if (!analytics) {
        return;
      }

      try {
        const payload = entries("payload" in event ? event.payload : undefined);
        const mapped = payload.map(([key, value]) => [snakeCase(key), value]);
        const params = fromPairs(mapped);

        logEvent(analytics, event.type, params);
      } catch (e) {
        console.warn(e);
      }
    },
    [analytics],
  );

  const setUser = useCallback(
    (userId: string | null) => {
      if (!analytics) {
        return;
      }

      try {
        setUserId(analytics, userId);
      } catch (e) {
        console.warn(e);
      }
    },
    [analytics],
  );

  const setUserDetails = useCallback(
    (properties: UserProperties) => {
      if (!analytics) {
        return;
      }

      try {
        setUserProperties(analytics, {
          organisation_id: properties.organisationId,
          organisation_name: properties.organisationName,
        });
      } catch (e) {
        console.warn(e);
      }
    },
    [analytics],
  );

  const value = useMemo(() => {
    return {
      log,
      setUserDetails,
      setUser,
      initialised: Boolean(analytics),
    };
  }, [log, setUserDetails, analytics, setUser]);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
}
