import { ChatErrorCode } from "@noa/types";

export const CONTACT_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLScofPzffLJj3766ZpsrWGnSBQBIbyz_jJrqYACCGTCAR5JmrQ/viewform";

export const getErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case ChatErrorCode.ERR_OPENAI:
      return `Noa has encountered an error. Please try again in a new chat or [contact the Noa team](${CONTACT_URL}).`;
    case ChatErrorCode.ERR_OPENAI_API:
      return `Noa has encountered an error. Please try again in a new chat or [contact the Noa team](${CONTACT_URL}).`;
    case ChatErrorCode.ERR_OPENAI_TIMEOUT:
      return `Noa is taking more time to complete this task. Please try again in a new chat or [contact the Noa team](${CONTACT_URL}).`;
    case ChatErrorCode.ERR_OPENAI_API_CONN:
      return `Noa has trouble getting an answer. Please make sure your network connection is stable and your firewall rules are not blocking the Noa services. Then please try again or [contact the Noa team](${CONTACT_URL}).`;
    case ChatErrorCode.ERR_OPENAI_INVALID_REQ:
      return `Invalid request to Noa! Please check that the content, format and size of your query is compatible with Noa services. You may also [contact the Noa team](${CONTACT_URL}).`;
    case ChatErrorCode.ERR_OPENAI_AUTH:
      return `Noa encountered an authorization error! Please try again. If the error persists, please [contact the Noa team](${CONTACT_URL})`;
    case ChatErrorCode.ERR_OPENAI_PERMISSION:
      return `Noa encountered a permission error!`;
    case ChatErrorCode.ERR_OPENAI_RATE_LIMIT:
      return `Noa is currently busy hadnling multiple requests. Please try again after a minute. If the error persists, please [contact the Noa team](${CONTACT_URL})`;
    case ChatErrorCode.ERR_OPENAI_SERVICE_UNAVAILABLE:
      return `Noa is currently unavailable. Please try again shortly.`;
    case ChatErrorCode.ERR_OPENAI_TRY_AGAIN:
      return `Something went wrong, please try again. If the error persists, please [contact the Noa team](${CONTACT_URL})`;
    case ChatErrorCode.ERR_OPENAI_CONTEXT_LENGTH_EXCEEDED:
      return `The message was too long, please split the text into multiple messages or try again with a shorter message. You may also switch to a new chat.`;
    case ChatErrorCode.ERR_PINECONE:
      return `Noa has encountered an error with its database. Please try again in a new chat or [contact the Noa team](${CONTACT_URL}).`;
    case ChatErrorCode.ERR_PINECONE_AUTH:
      return `Noa has encountered an error with its database. Please try again in a new chat or [contact the Noa team](${CONTACT_URL}).`;
    case ChatErrorCode.ERR_INVALID_EVENT_PAYLOAD:
      return `Invalid Event Payload error!`;
    default:
      return `Noa has encountered an error. Please try again in a new chat or [contact the Noa team](${CONTACT_URL}).`;
  }
};
