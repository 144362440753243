import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ValidationError } from "yup";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "ui";
import Textarea from "react-textarea-autosize";
import { Field } from "~/components/Form/Field";
import { Text } from "~/components/Text";

interface AddEmailModalProps {
  isOpen: boolean;
  onClose: () => void;

  onSave(emails: string[]): Promise<void>;
}

interface FormData {
  emails: string;
}

// Custom validation to check comma-separated emails
const emailSchema = yup.string().test((value) => {
  // Skip validation if empty, required() will handle it
  if (!value) {
    return true;
  }

  const emails = value
    .split(/[,\n]/g)
    .map((email) => email.trim())
    .filter(Boolean);

  const invalid = emails.filter(
    (email) => !yup.string().email().isValidSync(email),
  );

  if (invalid.length === 1) {
    return new ValidationError(
      `The following email is not valid: ${invalid[0]}`,
      value,
      "emails",
    );
  }

  if (invalid.length) {
    return new ValidationError(
      `The following emails are not valid: ${invalid.join(", ")}`,
      value,
      "emails",
    );
  }

  return true;
});

const schema = yup.object({
  emails: emailSchema.required("Email addresses are required"),
});

export const AddEmailModal = ({
  isOpen,
  onClose,
  onSave,
}: AddEmailModalProps) => {
  const {
    handleSubmit,
    formState: { isValid, isSubmitted },
    control,
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormData> = async (value) => {
    const emails = value.emails
      .split(/[,\n]/g)
      .map((email) => email.trim())
      .filter(Boolean);

    await onSave(emails);
    reset();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogContent
        className="!p-0 overflow-hidden gap-0"
        data-testid="add-organisation-users-modal"
      >
        <div className="space-y-8 bg-secondary px-6 py-5">
          <DialogTitle className="text-content-secondary">
            <Text id="admin.organisation.users.add.title" />
          </DialogTitle>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
          <Field
            control={control}
            label="admin.organisation.users.add.emails.label"
            placeholder="admin.organisation.users.add.emails.placeholder"
            name="emails"
          >
            {({ field }) => (
              <Textarea
                className="sm:min-h-[2rem] md:min-h-[8rem] max-h-[60vh] flex w-full rounded-md border border-input bg-background px-3 py-3 text-base text-content-primary font-normal ring-offset-background placeholder focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                {...field}
              />
            )}
          </Field>

          <DialogFooter>
            <div className="grid sm:grid-cols-2 gap-2 sm:ml-auto">
              <Button
                type="submit"
                data-testid="add-organisation-users-confirm-button"
                className="px-10"
                disabled={!isValid && isSubmitted}
              >
                <Text id="admin.organisation.users.add.confirm" />
              </Button>
              <DialogClose asChild>
                <Button
                  variant="outline"
                  data-testid="add-organisation-users-cancel-button"
                  className="px-10 sm:row-start-1"
                >
                  <Text id="admin.organisation.users.add.cancel" />
                </Button>
              </DialogClose>
            </div>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
