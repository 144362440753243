import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { Button, NoaLogo } from "ui";
import { useAuth } from "~/context/AuthContext";
import { useHeader } from "~/context/HeaderContext";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { useNotifications } from "~/context/NotificationsContext";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const header = useHeader();
  const { signInWithOauth } = useAuth();
  const { addNotification } = useNotifications();

  const { log, setUser } = useAnalytics();

  const signInWithGoogle = async () => {
    try {
      const credentials = await signInWithOauth("google");

      if (credentials) {
        setUser(credentials.user.uid);

        // delay to ensure user id is set
        setTimeout(() => log({ type: "login_success" }), 100);

        const redirect = params.get("redirect") ?? "/";

        navigate(redirect);
      }
    } catch (error) {
      log({
        type: "login_failed",
        payload: {
          errorCode: error.code,
          errorMessage: error.message,
        },
      });

      console.error("Failed to login with google", error.code, error.message);

      Sentry.captureException(error);

      if (error.code === "auth/internal-error") {
        addNotification(
          "You don't have access to Noa. Please contact",
          "error",
          {
            to: "/support",
            text: "customer support",
          },
          10000,
        );
      } else {
        addNotification(
          "Login failed. Please try again or contact",
          "error",
          {
            to: "/support",
            text: "customer support",
          },
          10000,
        );
      }
    }
  };

  useEffect(() => {
    header.setVisible(true);
  }, [header]);

  return (
    <section className="h-full w-full flex items-center justify-center">
      <section
        className="bg-background text-foreground p-8 rounded-md flex flex-col sm:min-w-[45rem] max-w-[800px] justify-between space-y-6"
        data-testid="login-card"
      >
        <header className="flex flex-row items-center justify-between">
          <h2 className="text-2xl font-semibold mr-8">
            Start your journey today
          </h2>

          <NoaLogo className="h-5 min-w-14" />
        </header>

        <p className="text-muted-foreground text-sm leading-loose">
          Login with your enterprise Gmail account
          <br /> and begin your NOA journey right away
        </p>

        <Button
          variant="outline"
          onClick={signInWithGoogle}
          data-testid="login-button"
          className="self-start"
        >
          <img src="/google.svg" alt="Google logo" className="mr-2" />
          Login with Google
        </Button>
      </section>
    </section>
  );
};

export default LoginPage;
