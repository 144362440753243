import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PersonasHeader from "~/components/PersonasHeader/PersonasHeader";
import { useChats } from "~/context/ChatsContext";
import { ChatInput } from "~/components/ChatInput/ChatInput";

const PersonaPage = () => {
  const navigate = useNavigate();
  const { taskId } = useParams();
  const { createTaskFromMessage } = useChats();

  if (!taskId) {
    navigate("/");
  }

  const onSubmitMessage = async (message: string) => {
    const id = await createTaskFromMessage(message, {
      taskId: taskId!,
    });

    navigate(`/chat/${id}`);
  };

  return (
    <section className="flex-1 flex flex-col relative h-full">
      <PersonasHeader />
      <div className="flex-1" />
      <div className="px-12 pb-5">
        <ChatInput
          onSubmit={onSubmitMessage}
          onUploadFile={() => null}
          onUploadFileSuccess={() => Promise.resolve()}
          showActions={false}
        />
      </div>
    </section>
  );
};

export default PersonaPage;
