import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import React from "react";

import { useAuth } from "~/context/AuthContext";
import LoginPage from "~/routes/Login/LoginPage";
import { UserProvider } from "~/context/UserContext";
import LogoutPage from "~/routes/Login/Logout";
import { FilesProvider } from "~/context/FilesContext";
import { ChatsProvider } from "~/context/ChatsContext";
import { OrganisationProvider } from "~/context/OrganisationContext";
import { SupportPage } from "~/routes/Support/SupportPage";
import { ThemeNotificationModal } from "~/components/Modal/ThemeNotificationModal";
import { userIsSuperAdmin } from "~/utils/authO";
import { AdminOrganisationsProvider } from "~/context/AdminOrganisationsContext";
import AdminRoute from "./Admin/AdminRoute";
import ChatRoute from "~/routes/Chat/ChatRoute";
import { TasksProvider } from "~/context/TasksContext";
import TasksRoute from "~/routes/Tasks/TasksRoute";
import MainLayout from "~/routes/Layout/MainLayout";

const AuthLayout = () => {
  const { authUser, loading } = useAuth();
  const { pathname } = useLocation();

  if (loading) {
    return null; // TODO: Add loading screen
  }

  if (!authUser) {
    const to = ["/", "/logout"].includes(pathname)
      ? "/login"
      : `/login?redirect=${pathname}`;

    return <Navigate to={to} />;
  }

  return (
    <UserProvider userId={authUser.uid}>
      <OrganisationProvider>
        <FilesProvider>
          <ChatsProvider>
            <TasksProvider>
              <Outlet />

              <ThemeNotificationModal />
            </TasksProvider>
          </ChatsProvider>
        </FilesProvider>
      </OrganisationProvider>
    </UserProvider>
  );
};

const AuthAdminLayout = () => {
  const { roles } = useAuth();
  const { pathname } = useLocation();

  if (!userIsSuperAdmin(roles)) {
    const to = ["/", "/logout"].includes(pathname)
      ? "/login"
      : `/login?redirect=${pathname}`;

    return <Navigate to={to} />;
  }

  return (
    <AdminOrganisationsProvider>
      <Outlet />
    </AdminOrganisationsProvider>
  );
};

function AppRoutes() {
  return (
    <Routes>
      <Route path="/support" element={<SupportPage />} />
      <Route path="/login" element={<LoginPage />} />

      <Route path="*" element={<AuthLayout />}>
        <Route path="logout" element={<LogoutPage />} />

        <Route path="admin" element={<AuthAdminLayout />}>
          <Route path="*" element={<AdminRoute />} />
        </Route>

        <Route path="tasks" element={<MainLayout />}>
          <Route path="*" element={<TasksRoute />} />
        </Route>

        <Route path="*" element={<ChatRoute />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
