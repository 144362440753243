import { File, FileStatus } from "@noa/types";
import {
  doc,
  DocumentReference,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useCallback } from "react";
import { ref, uploadBytesResumable } from "firebase/storage";
import { useFiles } from "~/context/FilesContext";
import { storage } from "~/integrations/firebase/storage";

export function useFile(id: string) {
  const context = useFiles();

  const { reference, files } = context;

  const file = files.find((localFile) => localFile.id === id);

  const uploadFileToStorage = useCallback(
    async (
      fileToUpload: Blob,
      onProgress: (progress: number) => void,
      signal: AbortSignal,
    ) => {
      if (!file) {
        return {
          status: "ERROR",
          message: "Unable to upload file, file not found",
        };
      }

      if (file.status !== FileStatus.UPLOADING) {
        return {
          status: "ERROR",
          message: `Unable to upload file, status is not ${FileStatus.UPLOADING}. Status: ${file.status}`,
        };
      }

      const fileReference = doc(reference, file.id) as DocumentReference<File>;

      try {
        const storageReference = ref(storage, file.gcsPath);

        const upload = () =>
          new Promise<void>((resolve, reject) => {
            const task = uploadBytesResumable(storageReference, fileToUpload);

            task.on(
              "state_changed",
              ({ bytesTransferred, totalBytes }) => {
                const progress = (bytesTransferred / totalBytes) * 100;

                onProgress(progress);
              },
              reject,
              resolve,
            );

            // Handle abort
            signal.addEventListener("abort", () => {
              task.cancel();
              reject(new DOMException("Aborted", "AbortError"));
            });
          });

        await upload();

        await updateDoc(fileReference, {
          status: FileStatus.UPLOADED,
          updatedAt: serverTimestamp(),
        });

        return {
          status: "SUCCESS",
        };
      } catch (e) {
        if (e instanceof DOMException && e.name === "AbortError") {
          return {
            status: "ABORTED",
          };
        }

        console.error("Error uploading file", e);

        await updateDoc(fileReference, {
          status: FileStatus.ERROR,
          updatedAt: serverTimestamp(),
        });

        return {
          status: "ERROR",
          message: e.message,
        };
      }
    },
    [file, reference],
  );

  return {
    ...context,
    file,
    uploadFileToStorage,
  };
}
