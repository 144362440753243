import { ChatMessage as IChatMessage, ChatStatus } from "@noa/types";
import React, { useMemo } from "react";
import { Loader } from "ui";
import { Timestamp } from "firebase/firestore";
import { useChat } from "~/context/ChatContext";
import { ChatMessage } from "./ChatMessage";

interface ChatMessagesProps {
  messages: IChatMessage[];

  scrollToBottom(): void;
}

const isMessageFromToday = (timestamp?: Timestamp): boolean => {
  if (!timestamp) {
    return false;
  }

  const messageDate = timestamp.toDate().toLocaleDateString();
  const todaysDate = new Date().toLocaleDateString();
  return todaysDate === messageDate;
};

export function ChatMessages({ messages, scrollToBottom }: ChatMessagesProps) {
  const { rateMessage, regenerateMessage, isReady, chat } = useChat();
  const shouldDisplayTodayDivider = useMemo(() => {
    return (
      chat.messages.length > 0 && isMessageFromToday(chat.messages[0].updatedAt)
    );
  }, [chat.messages]);

  return (
    <div className="space-y-6 max-w-chat mx-auto px-5 py-10">
      {shouldDisplayTodayDivider && (
        <div className="flex flex-row justify-center items-center">
          <div className="h-[1px] bg-content-secondary opacity-10 w-full" />
          <span className="absolute bg-background px-4 text-content-secondary text-xs">
            TODAY
          </span>
        </div>
      )}

      {messages?.map((message, index) => {
        const isLastMessage = index === messages.length - 1;
        return (
          <ChatMessage
            key={message.id}
            message={message}
            scrollToBottom={scrollToBottom}
            isReady={isReady}
            isLastMessage={isLastMessage}
            isTyping={isLastMessage && chat.status === ChatStatus.STREAMING}
            onRegenerate={regenerateMessage}
            onRate={rateMessage}
          />
        );
      })}
      {[ChatStatus.REQUESTED, ChatStatus.REGENERATE].includes(chat.status) && (
        <Loader className="pt-3" data-testid="chat-loading-indicator" />
      )}
    </div>
  );
}
