import styled from "styled-components";
import { FadeInOut } from "../FadeInOut";

interface LinkProps {
  href: string;
  children: React.ReactNode;
}

const StyledLink = styled.a`
  color: white;
`;

export const CustomLink: React.FC<
  LinkProps & React.HTMLProps<HTMLAnchorElement>
> = ({ href, children }) => (
  <StyledLink href={href} style={{ color: "white" }}>
    {children}
  </StyledLink>
);

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  flex-direction: column;
`;

interface NotificationProps {
  type?: "success" | "error" | "warning";
}

export const Notification = styled(FadeInOut)<NotificationProps>`
  background: ${({ type }) => (type === "error" ? "red" : "green")};
  display: flex;
  padding: 1rem 1rem;
  justify-content: center;
  align-items: center;
  pointer-events: initial;
  margin: 1rem 1rem 0;
  border-radius: 1.125rem;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.1),
    0 4px 16px 0 rgba(0, 0, 0, 0.1),
    0 16px 32px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
`;

export const ClearButton = styled.button`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  color: white;
  background: none;
  border: 0;
  padding: 0.25rem;
  flex-shrink: 0;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const Message = styled.div`
  margin: 1rem 0.5rem 1rem 0.75rem;
`;
