import { DocumentReference } from "firebase/firestore";
import React, { Dispatch, useEffect, useState } from "react";
import { FileStatus } from "@noa/types";
import { Progress } from "ui";
import { FileUploadErrorCode } from "src/components/FileUpload";
import { sumBy } from "lodash";
import { useFile } from "~/hooks/useFile";
import { useAnalytics } from "~/context/AnalyticsProvider";

const UploadProgress = ({
  docRef,
  image,
  onUploadFileSuccess,
  setError,
  setIsModalOpen,
}: {
  docRef: DocumentReference;
  image: File;
  onUploadFileSuccess(fileId: string): Promise<void>;
  setError: Dispatch<FileUploadErrorCode | undefined>;
  setIsModalOpen: Dispatch<boolean>;
}) => {
  const { uploadFileToStorage, file } = useFile(docRef.id);
  const [progress, setProgress] = useState<number>(0);
  const [bytesProgress, setBytesProgress] = useState(0);
  const { log } = useAnalytics();

  useEffect(() => {
    const stages: FileStatus[] = [FileStatus.UPLOADING, FileStatus.UPLOADED];

    if (!file?.status) {
      return setProgress(0);
    }

    // Don't set the status for errors as it's handled by another screen, and it animates before it updates the UI
    if (file.status === FileStatus.ERROR) {
      return;
    }

    const total = sumBy(stages, (stage) => {
      const index = stages.indexOf(stage);
      const current = stages.indexOf(file.status);

      // Show previous stages as complete
      if (current > index) {
        return 100;
      }

      // Animate as the bytes are uploaded
      if (current === index && stage === FileStatus.UPLOADING) {
        return bytesProgress;
      }

      // Show current stage as almost complete
      if (current === index) {
        return 90;
      }

      return 0;
    });

    setProgress(total / stages.length);
  }, [file?.status, bytesProgress]);

  useEffect(() => {
    const controller = new AbortController();

    const upload = async () => {
      try {
        const result = await uploadFileToStorage(
          image,
          (uploadProgress) => {
            setBytesProgress((current) => Math.max(current, uploadProgress));
          },
          controller.signal,
        );

        if (result.status === "ERROR") {
          throw new Error(result.message);
        }

        if (result.status === "SUCCESS") {
          await onUploadFileSuccess(docRef.id);
          log({
            type: "image_upload_success",
          });
          setIsModalOpen(false);
        }
      } catch (e) {
        console.error("Failed to upload file");
        console.error(e);

        setError("FILE_UPLOAD_FAILED");
      }
    };

    upload().then();
    return () => {
      controller.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docRef.id, image, log, setError, setIsModalOpen]);

  return (
    <span className="flex flex-col items-center w-full">
      <Progress progress={progress} className="h-16 w-16" />
      <span className="pt-8 text-content-secondary font-medium text-lg pb-2">
        Uploading Image
      </span>
      <span className="text-content-tertiary">
        Please wait while the image is being uploaded
      </span>
    </span>
  );
};

export default UploadProgress;
