import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from "./AdminLayout";
import { AdminOrganisationsPage } from "./AdminOrganisationsPage";
import { AdminOrganisationDetailPage } from "./AdminOrganisationDetailPage";
import { AdminOrganisationProvider } from "~/context/AdminOrganisationContext";

export default function AdminRoute() {
  return (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route index element={<Navigate to="organisations" replace />} />

        <Route path="organisations">
          <Route index element={<AdminOrganisationsPage />} />
          <Route
            path=":id"
            element={
              <AdminOrganisationProvider>
                <AdminOrganisationDetailPage />
              </AdminOrganisationProvider>
            }
          />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}
