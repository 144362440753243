import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Organisation, OrganisationPrivate } from "@noa/types";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
  QueryDocumentSnapshot,
  where,
  getCountFromServer,
  CollectionReference,
} from "firebase/firestore";
import * as Sentry from "@sentry/react";

import { db } from "~/integrations/firebase/firestore";

type OrganisationActiveUsersCount = { [k: string]: number };
interface AdminOrganisationsContextProps {
  organisations: Organisation[];
  organisationsReference: CollectionReference<Organisation>;
  organisationsPrivate: OrganisationPrivate[];
  organisationsPrivateReference: CollectionReference<OrganisationPrivate>;
  organisationActiveUsersCount: OrganisationActiveUsersCount;
}

export const AdminOrganisationsContext =
  createContext<AdminOrganisationsContextProps>(undefined as never);

export const useAdminOrganisations = () =>
  useContext(AdminOrganisationsContext);

const organisationConverter = {
  toFirestore(organisation: Organisation) {
    return organisation;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<Organisation>): Organisation {
    return snapshot.data();
  },
};

const organisationPrivateConverter = {
  toFirestore(organisation: OrganisationPrivate) {
    return organisation;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<OrganisationPrivate>,
  ): OrganisationPrivate {
    return snapshot.data();
  },
};

export const AdminOrganisationsProvider = ({ children }: PropsWithChildren) => {
  const organisationsReference = useMemo(
    () => collection(db, "organisations").withConverter(organisationConverter),
    [],
  );

  const organisationsQuery = useMemo(() => {
    const filtered = query(organisationsReference);

    return filtered;
  }, [organisationsReference]);

  const [organisations = [], organisationsLoading] =
    useCollectionData<Organisation>(organisationsQuery);

  const organisationsPrivateReference = useMemo(
    () =>
      collection(db, "organisationsPrivate").withConverter(
        organisationPrivateConverter,
      ),
    [],
  );

  const organisationsPrivateQuery = useMemo(() => {
    const filtered = query(organisationsPrivateReference);

    return filtered;
  }, [organisationsPrivateReference]);

  const [organisationsPrivate = [], organisationsPrivateLoading] =
    useCollectionData<OrganisationPrivate>(organisationsPrivateQuery);

  const [organisationActiveUsersCount, setOrganisationActiveUsersCount] =
    useState({} as OrganisationActiveUsersCount);

  const [fetchingUserCount, setFetchingUserCount] = useState(true);

  const fetchUserCounts = useCallback(
    async (signal: AbortSignal) => {
      setFetchingUserCount(true);
      const updatedOrganisationActiveUsersCount: OrganisationActiveUsersCount =
        {};

      try {
        const userCountPromises = organisations.map(async (organisation) => {
          if (signal.aborted) return;

          const usersRef = collection(db, "users");
          const userCountQuery = query(
            usersRef,
            where("organisationId", "==", organisation.id),
          );

          const userCountSnapshot = await getCountFromServer(userCountQuery);
          if (signal.aborted) return;

          const userCount = userCountSnapshot.data().count;
          updatedOrganisationActiveUsersCount[organisation.id] = userCount;
        });

        await Promise.all(userCountPromises);

        if (!signal.aborted) {
          setOrganisationActiveUsersCount(updatedOrganisationActiveUsersCount);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          Sentry.captureException(error);
        }
      } finally {
        setFetchingUserCount(false);
      }
    },
    [organisations],
  );

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    if (organisations.length > 0) {
      fetchUserCounts(signal);
    }

    return () => {
      controller.abort();
    };
  }, [organisations, fetchUserCounts]);

  const value = useMemo(() => {
    return {
      organisations,
      organisationsPrivate,
      organisationActiveUsersCount,
      organisationsReference,
      organisationsPrivateReference,
    };
  }, [
    organisationActiveUsersCount,
    organisations,
    organisationsPrivate,
    organisationsReference,
    organisationsPrivateReference,
  ]);

  if (
    organisationsLoading ||
    organisationsPrivateLoading ||
    fetchingUserCount
  ) {
    return null;
  }

  return (
    <AdminOrganisationsContext.Provider value={value}>
      {children}
    </AdminOrganisationsContext.Provider>
  );
};
