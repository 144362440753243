import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Input,
  Label,
} from "ui";
import { useChats } from "~/context/ChatsContext";

interface Props {
  show?: boolean;
  id?: string;

  onClose(): void;

  onSubmit(title: string): Promise<void>;
}

export function RenameChatModal({ id, show, onClose, onSubmit }: Props) {
  const { chats = [] } = useChats();

  const title = chats.find((c) => c.id === id)?.title;

  const { register, handleSubmit, setValue, formState, reset } = useForm({
    defaultValues: {
      title: title ?? "",
    },
  });

  useEffect(() => {
    setValue("title", title ?? "");
  }, [title, setValue]);

  return (
    <Dialog
      open={show}
      onOpenChange={(open) => {
        if (!open) {
          reset();
          onClose();
        }
      }}
    >
      <DialogContent
        className="!p-0 overflow-hidden gap-0"
        data-testid="rename-chat-modal"
      >
        <div className="space-y-8 bg-secondary px-6 py-5">
          <DialogTitle className="text-content-secondary">
            Rename chat
          </DialogTitle>
        </div>

        <form
          onSubmit={handleSubmit((values) => onSubmit(values.title))}
          className="p-6 space-y-6"
        >
          <Label className="space-y-3">
            <div>Chat name</div>
            <div>
              <Input
                {...register("title", { required: true })}
                data-testid="rename-chat-input"
              />
            </div>
          </Label>

          <DialogFooter>
            <div className="grid sm:grid-cols-2 gap-2 sm:ml-auto">
              <Button
                type="submit"
                data-testid="rename-chat-confirm-button"
                className="px-10"
                disabled={!formState.isValid}
              >
                Save changes
              </Button>
              <DialogClose asChild>
                <Button
                  variant="outline"
                  data-testid="rename-chat-cancel-button"
                  className="px-10 sm:row-start-1"
                >
                  Cancel
                </Button>
              </DialogClose>
            </div>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
