import React from "react";
import { Button, Label } from "ui";
import { UserPersonaTask } from "@noa/types";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { ref } from "firebase/storage";
import { storage } from "~/integrations/firebase/storage";

interface Props {
  persona: UserPersonaTask;
  handleSlideOutPanel: (arg: UserPersonaTask) => void;
}

const PersonasCard = ({ persona, handleSlideOutPanel }: Props) => {
  const [personaUrl] = useDownloadURL(ref(storage, persona.avatarGCSPath));

  return (
    <div
      data-testid={persona.id}
      key={persona.id}
      className="group max-h-[300px] max-w-[247px] rounded-lg relative overflow-hidden "
    >
      <img
        className="object-cover w-full h-full"
        src={personaUrl}
        alt={`${persona.name} smiling`}
      />

      <div className="absolute z-1 inset-0 bg-gradient-to-t from-black to-25% bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all" />
      <Label className="absolute z-3 top-2 right-2 bg-background px-3 py-2 rounded-md bg-black bg-opacity-30 text-white text-sm font-semibold ">
        {persona.generation}
      </Label>

      <div className="absolute left-0 bottom-0 w-full flex flex-col px-4 pb-4 transition-transform duration-500 transform translate-y-[60px] group-hover:translate-y-0">
        <span className="text-md text-white font-bold">{persona.name}</span>
        <span className="text-sm text-white">22 years old</span>
        <Button
          variant="secondary"
          className="mt-4 w-full"
          aria-label="View details about Emma Taylor"
          onClick={() => handleSlideOutPanel(persona)}
        >
          See details
        </Button>
      </div>
    </div>
  );
};

export default PersonasCard;
