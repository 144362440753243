import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export type Theme = "light" | "dark";
export type ThemePreference = Theme | "system";

interface IThemeContext {
  theme: Theme;
  preference: ThemePreference;

  setTheme(preference: ThemePreference): void;
}

const DEFAULT_PREFERENCE: ThemePreference = "system";
const THEME_KEY = "theme";

export const ThemeContext = createContext<IThemeContext>(undefined as never);

function getSystemPreference(): Theme {
  return window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";
}

function getTheme(): Theme {
  const theme = localStorage.getItem(THEME_KEY) as Theme;

  return theme ?? getSystemPreference();
}

function getPreference(): ThemePreference {
  const theme = localStorage.getItem(THEME_KEY) as Theme | undefined;

  return theme ?? DEFAULT_PREFERENCE;
}

function applyTheme() {
  const theme = getTheme();

  document.body.classList.remove("light", "dark");
  document.body.classList.add(theme);
}

applyTheme();

export const useTheme = () => useContext(ThemeContext);

export function ThemeProvider({ children }: PropsWithChildren) {
  const [preference, setPreference] = useState(getPreference);

  const theme = useMemo(() => {
    if (preference === "system") {
      localStorage.removeItem("theme");
    } else {
      localStorage.setItem("theme", preference);
    }

    return getTheme();
  }, [preference]);

  useEffect(() => {
    applyTheme();
  }, [theme]);

  const value = useMemo(
    () => ({
      theme,
      preference,
      setTheme: setPreference,
    }),
    [theme, preference],
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}
