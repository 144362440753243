/* eslint-disable */
// @ts-nocheck
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  collection,
  CollectionReference,
  query,
  QueryDocumentSnapshot,
} from "firebase/firestore";

import { db } from "~/integrations/firebase/firestore";
import { Task } from "@noa/types/Task";
import { groupBy } from "lodash";

export interface NoaTask {
  [key: string]: Task[];
}

interface TasksContext {
  tasks: NoaTask;
  loading: boolean;
  reference: CollectionReference<Task>;
}

export const TasksContext = createContext<TasksContext>(undefined as never);

export const useTasks = () => useContext(TasksContext);

const taskConverter = {
  toFirestore(task: Task) {
    return task;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Task {
    return snapshot.data() as unknown as Task;
  },
};

export const TasksProvider = ({ children }: PropsWithChildren) => {
  const reference = collection(db, "tasks").withConverter(taskConverter);

  const userTasksQuery = useMemo(() => {
    return query(reference);
  }, [reference]);

  const [tasks = [], loading] = useCollectionData<Task>(userTasksQuery);

  const noaTasks = useMemo(() => groupBy(tasks, "category"), [tasks]);

  return (
    <TasksContext.Provider
      value={{
        loading,
        tasks: noaTasks,
        reference,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};
