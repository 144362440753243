import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PersonasPage from "~/routes/Tasks/Personas/PersonasPage";
import PersonaPage from "~/routes/Tasks/Persona/PersonaPage";

export default function TasksRoute() {
  return (
    <Routes>
      <Route index element={<Navigate to="/" />} />
      <Route path="personas" element={<PersonasPage />} />
      <Route path="personas/:taskId/chat" element={<PersonaPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
