import { useDownloadURL } from "react-firebase-hooks/storage";
import { ref } from "firebase/storage";
import { useMemo } from "react";
import { FileTypeImage } from "@noa/types";
import { useFile } from "~/hooks/useFile";
import { storage } from "~/integrations/firebase/storage";

interface Props {
  fileId: string;
}

export function ChatMessageFile({ fileId }: Props) {
  const { file } = useFile(fileId);

  const isImage = useMemo(() => {
    if (!file?.fileType) {
      return false;
    }

    return Object.values(FileTypeImage).includes(
      file.fileType as FileTypeImage,
    );
  }, [file?.fileType]);

  const [src] = useDownloadURL(
    file?.gcsPath && isImage ? ref(storage, file.gcsPath) : undefined,
  );

  if (!isImage || !file || !src) {
    return null;
  }

  return (
    <img
      src={src}
      data-testid="chat-message-image"
      alt={file.fileName}
      className="max-w-[250px] max-h-[350px] object-contain"
    />
  );
}
