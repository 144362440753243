import { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { useText } from "./TextProvider";

interface Props<LocaleString extends string> {
  id: LocaleString;
  locale?: string;
  multiline?: boolean;
  className?: string;
}

export function Text<LocaleString extends string = string>({
  id,
  locale = "en",
  multiline,
  className,
}: Props<LocaleString>) {
  const text = useText();

  const elements = useMemo(() => {
    const elements = ["strong", "i", "a"];

    return multiline ? elements.concat(["p"]) : elements;
  }, [multiline, text]);

  const value = useMemo(() => text(id), [id]);

  return (
    <ReactMarkdown
      className={className}
      allowedElements={elements}
      unwrapDisallowed={true}
      linkTarget={(url) => (url.startsWith("http") ? "_blank" : undefined)}
    >
      {value}
    </ReactMarkdown>
  );
}
