import { HTMLAttributes } from "react";
import { cn } from "../utils";

export function PageLayout({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={cn("bg-background m-2 rounded-2xl shadow-sm", className)}
    >
      {children}
    </div>
  );
}
