import React from "react";
import { Warning } from "@phosphor-icons/react";
import { Text } from "@noa/locales";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "ui";

interface Props {
  show?: boolean;

  onClose(): void;

  onDelete(): void;
}

export function DeleteChatModal({ onClose, onDelete, show }: Props) {
  return (
    <Dialog
      open={show}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogContent data-testid="delete-chat-modal">
        <DialogHeader className="space-y-8">
          <Warning className="text-destructive w-10 h-10 mx-auto" />

          <div className="space-y-4 pb-4">
            <DialogTitle className="text-center text-xl">
              <Text id="chats.delete.title" />
            </DialogTitle>

            <div className="text-sm text-content-tertiary text-center leading-loose">
              <Text multiline id="chats.delete.subtitle" />
            </div>
          </div>
        </DialogHeader>

        <DialogFooter>
          <div className="grid sm:grid-cols-2 gap-4 mx-auto">
            <DialogClose asChild>
              <Button
                onClick={onDelete}
                data-testid="delete-chat-confirm-button"
                className="px-10"
              >
                Delete Chat
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button
                variant="outline"
                data-testid="delete-chat-cancel-button"
                className="px-10 sm:row-start-1"
              >
                Cancel
              </Button>
            </DialogClose>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
