import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { User } from "firebase/auth";
import { startCase } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckCircle } from "@phosphor-icons/react";
import { Chat } from "@noa/types";
import { useText } from "@noa/locales";
import { Button, Card, Textarea } from "ui";
import { Text } from "~/components/Text";
import { createTicket, TicketCategory } from "~/integrations/zendesk";
import { Field } from "~/components/Form/Field";
import { groupChatsByAge } from "~/utils/chat";
import { validationSchema } from "~/utils/supportFormValidator";

interface FormValues {
  email: string;
  name: string;
  message: string;
  category: TicketCategory;
  chatId?: string;
}

interface SupportFormProps {
  user?: Pick<User, "displayName" | "email"> | null;
  chats?: Pick<Chat, "id" | "title" | "updatedAt">[];
}

export function SupportForm({ user, chats }: SupportFormProps) {
  const text = useText();

  const { handleSubmit, reset, control, formState } = useForm<FormValues>({
    values: {
      name: user?.displayName ?? "",
      email: user?.email ?? "",
      category: "" as TicketCategory,
      message: "",
      chatId: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: FormValues) => {
    await createTicket({
      name: values.name,
      email: values.email,
      message: values.message,
      category: values.category,
      chatId: values.chatId,
    });

    reset();
  };

  const chatsByAge = useMemo(() => {
    if (!chats?.length) {
      return [];
    }

    return groupChatsByAge(chats);
  }, [chats]);

  if (formState.isSubmitSuccessful) {
    return (
      <div className="text-center flex items-center flex-col space-y-8">
        <CheckCircle className="w-16 h-16 text-check" />
        <div className="text-2xl font-medium">
          <Text id="support.form.success.title" />
        </div>
        <div className="text-content-tertiary prose dark:prose-invert">
          <Text id="support.form.success.message" multiline />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-12">
      <div className="space-y-8">
        <div className="font-semibold text-3xl">
          <Text id="support.form.title" />
        </div>
        <div className="space-y-4 prose dark:prose-invert max-w-full w-auto text-content-tertiary">
          <Text id="support.form.subtitle" multiline />
        </div>
      </div>

      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-1 gap-6"
          data-testid="support-form"
        >
          <div className="grid sm:grid-cols-2 gap-6">
            <Field
              control={control}
              name="name"
              disabled={!!user}
              label="support.form.fields.name.label"
              placeholder="support.form.fields.name.placeholder"
            />

            <Field
              control={control}
              name="email"
              disabled={!!user}
              label="support.form.fields.email.label"
              placeholder="support.form.fields.email.placeholder"
            />
          </div>

          <div className="grid sm:grid-cols-2 gap-6">
            <Field
              control={control}
              name="category"
              label="support.form.fields.category.label"
              placeholder="support.form.fields.category.placeholder"
            >
              {({ field }) => (
                <select
                  {...field}
                  className="bg-input-background border border-input px-2 py-3 rounded-md"
                >
                  <option disabled value="" className="placeholder">
                    Select a reason
                  </option>
                  {Object.values(TicketCategory).map((option) => (
                    <option key={option} value={option} data-value={option}>
                      {startCase(option)}
                    </option>
                  ))}
                </select>
              )}
            </Field>

            {!!user && (
              <Field
                control={control}
                name="chatId"
                label="support.form.fields.chat.label"
                placeholder="support.form.fields.chat.placeholder"
              >
                {({ field }) => (
                  <select
                    {...field}
                    className="bg-input-background border border-input px-2 py-3 rounded-md w-full"
                  >
                    <option value="" disabled className="placeholder">
                      {text("support.form.fields.chat.placeholder")}
                    </option>

                    {chatsByAge.map(({ title, items }) => (
                      <optgroup
                        label={title}
                        key={title}
                        data-testid="support-chats-group"
                      >
                        {items.map((chat) => (
                          <option
                            key={chat.id}
                            value={chat.id}
                            data-value={chat.id}
                          >
                            {chat.title?.substring(0, 50)}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                )}
              </Field>
            )}
          </div>

          <Field
            control={control}
            label="support.form.fields.message.label"
            placeholder="support.form.fields.message.placeholder"
            name="message"
            length={2000}
          >
            {({ field }) => (
              <Textarea
                {...field}
                className="min-h-[10rem] max-h-[20rem] outline-0 resize-none w-full px-4 py-3 border border-input-border rounded-md bg-input-background"
              />
            )}
          </Field>

          <div>
            <Button
              type="submit"
              disabled={formState.isSubmitted && !formState.isValid}
            >
              Send message
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
}
