import { PropsWithChildren, MouseEvent, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { styled } from "styled-components";

interface Options {
  direction?: "up" | "down";
  translate?: "up" | "down";
}

function translateY(options: {
  $translate?: boolean;
  $direction?: Options["direction"];
}) {
  if (!options.$translate) {
    return 0;
  }

  if (!options.$direction || options.$direction === "up") {
    return 50;
  }

  return -50;
}

const Container = styled(Transition)<{
  $fadeOut: boolean;
  $delay?: number;
  $speed?: number;
  $speedOut?: number;
  $translate?: boolean;
  $direction?: Options["direction"];
}>`
  transition: ${({ $speed, $speedOut }) =>
    `${$speed}s transform cubic-bezier(0.33, 1, 0.68, 1), ${$speedOut}s opacity cubic-bezier(0.33, 1, 0.68, 1)`};

  opacity: ${({ $fadeOut }) => ($fadeOut ? 0 : 1)};
  transform: translateY(${({ $fadeOut }) => ($fadeOut ? translateY : 0)}px);

  transition-delay: ${({ $delay }) =>
    `${$delay === 0 ? 0 : ($delay ?? 0) + 250}ms`};
  &.hide {
    opacity: 0;
    transform: translateY(${translateY}px);
  }
`;

interface Props {
  delay?: number;
  className?: string;
  translate?: boolean;
  speedIn?: number;
  speedOut?: number;
  direction?: Options["direction"];
  timeout?: number;
  onClick?(event: MouseEvent<HTMLElement>): void;
}

export function FadeInOut({
  delay,
  translate = true,
  direction = "up",
  speedIn = 0.8,
  speedOut = 0.8,
  timeout,
  ...props
}: PropsWithChildren<Props>) {
  const [show, setShow] = useState(false);
  const [fadeOutAnimation, setFadeOutAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (timeout) {
      setTimeout(
        () => {
          setFadeOutAnimation(true);
        },
        timeout - speedOut * 1000 - (delay || 0),
      );
    }
  }, [delay, speedOut, timeout]);

  return (
    <Container
      show={show}
      enterFrom="hide"
      enterTo="show"
      leaveFrom="show"
      leaveTo="hide"
      $fadeOut={fadeOutAnimation}
      $speed={speedIn}
      $speedOut={speedOut}
      $direction={direction}
      $translate={translate}
      $delay={delay}
      {...props}
    />
  );
}
