import axios from "axios";
import { entries } from "lodash";
import { UAParser } from "ua-parser-js";

export enum CustomFields {
  CATEGORY = 12646698384540,
  CHAT_ID = 13622370851228,
}

export enum TicketCategory {
  question = "question",
  access_issue = "access_issue",
  feature_request = "feature_request",
  bug = "bug",
  feedback = "feedback",
  use_case = "use_case",
  other = "other",
}

type Metadata = Record<string, string | number | undefined>;

interface Ticket {
  subject?: string;
  name: string;
  email: string;
  message: string;
  chatId?: string;
  category: TicketCategory;
  metadata?: Metadata;
}

function getDefaultMetadata() {
  try {
    const metadata: Array<[string, string | undefined]> = [];

    const parser = new UAParser();
    const results = parser.getResult();

    metadata.push(
      ["Browser", `${results.browser.name} (${results.browser.version})`],
      ["OS", `${results.os.name} (${results.os.version})`],
      ["Device", `${results.device.model} (${results.device.vendor})`],
    );

    return metadata;
  } catch (e) {
    console.error("Failed to get default metadata", e);

    return [];
  }
}

function createBody(message: string, metadata: Metadata) {
  let body = message;

  const data = [...entries(metadata), ...getDefaultMetadata()];

  body += "\n\n----------\n";

  // eslint-disable-next-line no-restricted-syntax
  for (const [property, value] of data) {
    if (!value) {
      // eslint-disable-next-line no-continue
      continue;
    }

    body += `**${property}**: ${value}\n`;
  }

  return body;
}

export async function createTicket(ticket: Ticket) {
  const {
    subject,
    name,
    email,
    message,
    category,
    metadata = {},
    chatId,
  } = ticket;

  const body = createBody(message, metadata);

  const request = {
    subject: subject || message.substring(0, 100),
    requester: {
      name: name.trim(),
      email: email.trim(),
    },
    comment: {
      body: body.trim(),
    },
    custom_fields: [
      {
        id: CustomFields.CATEGORY,
        value: category.trim(),
      },
    ],
  };

  if (chatId?.trim()) {
    request.custom_fields.push({
      id: CustomFields.CHAT_ID,
      value: chatId.trim(),
    });
  }

  return axios.post(import.meta.env.VITE_ZENDESK_REQUESTS_URL, {
    request,
  });
}
