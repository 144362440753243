import * as firestore from "firebase/firestore";
import {
  connectFirestoreEmulator,
  DocumentReference,
  Firestore,
  getFirestore,
  WithFieldValue,
} from "firebase/firestore";
import { app } from "./app";

export const db: Firestore = getFirestore(app);

if (import.meta.env.VITE_FIREBASE_EMULATOR_ENABLED) {
  connectFirestoreEmulator(db, "localhost", 8081);
}

export async function setDoc<T>(
  reference: DocumentReference<T>,
  data: WithFieldValue<T>,
) {
  if (window.Cypress) {
    window.Cypress.data = window.Cypress.data ?? [];
    window.Cypress.data.push(reference.path);
  }

  return firestore.setDoc(reference, data);
}
