import { SidebarSimple } from "@phosphor-icons/react";
import React from "react";
import { Button, Header } from "ui";
import { cn } from "~/lib/utils";
import { AccountMenu } from "~/components/AccountMenu/AccountMenu";
import { useLayout } from "~/context/LayoutContext";
import { useAnalytics } from "~/context/AnalyticsProvider";

const PersonasHeader = () => {
  const { showSidebar, toggleSidebar } = useLayout();

  const { log } = useAnalytics();

  const onToggleSidebar = () => {
    log({
      type: "sidebar_toggle_clicked",
      payload: {
        closed: !showSidebar,
      },
    });

    toggleSidebar();
  };

  return (
    <Header
      className={cn("gap-4 grid  grid-cols-[auto,1fr,auto] items-center")}
    >
      {!showSidebar && (
        <Button
          size="icon"
          variant="ghost"
          onClick={onToggleSidebar}
          data-testid="chat-header-sidebar-toggle"
        >
          <SidebarSimple className="h-8 w-8 text-icons-50" />
        </Button>
      )}

      <div className="grid grid-cols-[1fr,auto] gap-2 mr-auto items-center">
        <div className="text-xl font-medium truncate">User personas</div>
      </div>

      <AccountMenu />
    </Header>
  );
};

export default PersonasHeader;
