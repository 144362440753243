import { asyncWithLDProvider, LDContext } from "launchdarkly-react-client-sdk";

export type AnonymousContext = { anonymous: true; key?: string };

export interface Context {
  user:
    | { key: string; name?: string; email?: string; avatar?: string }
    | AnonymousContext;
  organisation: { key: string; name?: string } | AnonymousContext;
}

export type ContextKey = keyof Context;

export const ANONYMOUS_CONTEXT: AnonymousContext = {
  anonymous: true,
  key: window.Cypress ? "anonymous" : undefined,
};

export const INITIAL_CONTEXT: LDContext = {
  kind: "multi",
  user: ANONYMOUS_CONTEXT,
};

export async function initialiseLaunchDarkly() {
  return asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
    context: INITIAL_CONTEXT,
  });
}
