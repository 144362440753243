import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from "react";

export const TextContext = createContext<Record<string, string>>(
  undefined as never,
);

export function useText<LocaleStrings extends string = string>() {
  const strings = useContext(TextContext) as Record<LocaleStrings, string>;

  return useCallback(
    (id: LocaleStrings) => {
      if (!strings) {
        return id;
      }

      return strings[id] ?? id;
    },
    [strings],
  );
}

interface Props {
  value: Record<string, string>;
}

export function TextProvider({ value, children }: PropsWithChildren<Props>) {
  return <TextContext.Provider value={value}>{children}</TextContext.Provider>;
}
