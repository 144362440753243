import { ChatMessage as IChatMessage, RatingType } from "@noa/types";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { ChatMessage as Message } from "ui";
import { auth } from "~/integrations/firebase/auth";
import { useTheme } from "~/context/ThemeProvider";
import { ChatMessageActions } from "~/components/ChatMessage/ChatMessageActions";
import { copyMessage } from "~/utils/copyMessage";
import { ChatMessageFeedback } from "~/components/ChatMessages/ChatMessageFeedback";
import { ChatMessageFile } from "~/components/ChatMessages/ChatMessageFile";

export interface ChatMessageProps {
  message: IChatMessage;
  isLastMessage: boolean;
  isReady: boolean;
  isTyping: boolean;

  scrollToBottom(): void;

  onRate(id: string, rating: RatingType, message: string | null): Promise<void>;

  onRegenerate(): void;
}

export function ChatMessage({
  message,
  isLastMessage,
  isReady,
  isTyping,
  onRegenerate,
  onRate,
  scrollToBottom,
}: ChatMessageProps) {
  const { theme } = useTheme();
  const [showFeedback, setShowFeedbackModal] = useState(false);

  async function onRateMessage(
    id: string,
    rating: RatingType,
    text: string | null,
  ) {
    if (rating === RatingType.DISLIKE) {
      setShowFeedbackModal(true);
    }

    await onRate(id, rating, text);
  }

  useEffect(() => {
    // If the message is the last in the view, scroll down to ensure it's visible to the user
    if (isLastMessage) {
      setTimeout(() => scrollToBottom(), 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFeedback, isLastMessage]);

  const [authUser] = useAuthState(auth);

  return (
    <>
      <Message
        id={message.id}
        content={message.content ?? ""}
        theme={theme}
        sender={message.role === "AI" ? "NOA" : authUser?.displayName!}
        date={message.updatedAt?.toDate() ?? new Date()}
        avatar={message.role === "AI" ? "/noa.svg" : authUser?.photoURL!}
        onType={scrollToBottom}
        isTyping={isTyping}
        actions={
          <ChatMessageActions
            message={message}
            isLastMessage={isLastMessage}
            isReady={isReady}
            onRegenerate={onRegenerate}
            onLike={() => onRateMessage(message.id, RatingType.LIKE, null)}
            onDislike={() =>
              onRateMessage(message.id, RatingType.DISLIKE, null)
            }
            onCopy={() => copyMessage(message.id, message.content!)}
          />
        }
        files={
          message.files &&
          message.files.length > 0 && (
            <ChatMessageFile fileId={message.files[0]} />
          )
        }
      />

      {showFeedback && (
        <ChatMessageFeedback
          onSubmit={(feedback) =>
            onRateMessage(message.id, RatingType.DISLIKE, feedback)
          }
          onClose={() => setShowFeedbackModal(false)}
        />
      )}
    </>
  );
}
