import { TriangleAlert } from "lucide-react";
import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogTitle,
  DialogTrigger,
} from "ui";

interface RemoveEmailConfirmationProps {
  isOpen: boolean;
  email: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const RemoveEmailConfirmationModal: React.FC<
  RemoveEmailConfirmationProps
> = ({ isOpen, email, onClose, onConfirm }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogTrigger />
      <DialogOverlay />

      <DialogContent className="flex flex-col gap-8">
        <DialogTitle>
          <div className="flex items-cente justify-center">
            <TriangleAlert className="h-10 text-destructive" />
          </div>
        </DialogTitle>

        <div className="flex flex-col gap-2">
          <span>Are you sure you want to remove {email}?</span>

          <span className="text-muted-foreground">
            {email} will no longer have access to Noa
          </span>
        </div>

        <div className="flex flex-row-reverse gap-3 items-stretch flex-row-reverse">
          <Button onClick={onConfirm} variant="default" className="py-4 flex-1">
            Remove
          </Button>
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            className="py-4 flex-1"
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
