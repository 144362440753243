import { UserRole } from "@noa/types";
import { Badge } from "ui";

function roleToText(role?: UserRole) {
  switch (role) {
    case "SUPER_ADMIN":
      return "Super Admin";
    default:
      return "User";
  }
}

export const PermissionBadge = ({ userRole }: { userRole?: UserRole }) => {
  return (
    <Badge className="text-action-primary" variant="outline">
      {roleToText(userRole)}
    </Badge>
  );
};
