import React from "react";
import { NoaIcon } from "ui";
import { Text } from "~/components/Text";

const IntroSection: React.FC = () => {
  return (
    <div
      className="flex flex-col flex-1 items-center justify-center max-w-[40rem] px-5"
      data-testid="intro-section"
    >
      <div className="text-center space-y-10">
        <div className="space-y-6">
          <NoaIcon className="w-16 h-16 mx-auto" />
          <div className="text-action-primary font-medium">
            <Text id="chats.intro.greeting" />
          </div>
        </div>

        <div className="space-y-6">
          <div className="text-3xl text-content-secondary">
            <Text id="chats.intro.title" />
          </div>
          <div className="leading-7 text-content-tertiary prose dark:prose-invert">
            <Text id="chats.intro.message" multiline />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
