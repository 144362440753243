/* eslint-disable no-continue,no-restricted-syntax */
import { Chat, Role } from "@noa/types";
import {
  format,
  isAfter,
  isThisYear,
  isToday,
  isYesterday,
  subDays,
} from "date-fns";
import { entries, orderBy, startCase } from "lodash";

export function getChatTitle(chat?: Pick<Chat, "title" | "messages">) {
  if (!chat) {
    return "";
  }

  const { title, messages } = chat;

  if (title) {
    return title;
  }

  const userMessages = messages?.filter((c) => c.role === Role.USER);

  return userMessages?.[0].content ?? "New chat";
}

export function groupChatsByAge(
  chats: Pick<Chat, "id" | "updatedAt" | "title">[],
) {
  const mapped = chats.map((chat) => ({
    ...chat,
    updatedAt: chat.updatedAt?.toDate(),
  }));

  const sections: Record<string, typeof mapped> = {
    today: [],
    yesterday: [],
    last7days: [],
    last30days: [],
    older: [],
  };

  const now = new Date();

  for (const chat of mapped) {
    const date = chat.updatedAt;

    if (!date || isToday(date)) {
      sections.today.push(chat);
      continue;
    }

    if (isYesterday(date)) {
      sections.yesterday.push(chat);
      continue;
    }

    if (isAfter(date, subDays(now, 7))) {
      sections.last7days.push(chat);
      continue;
    }

    if (isAfter(date, subDays(now, 30))) {
      sections.last30days.push(chat);
      continue;
    }

    if (isThisYear(date)) {
      const month = format(date, "MMMM");

      sections[month] = sections[month] || [];
      sections[month].push(chat);

      continue;
    }

    const year = format(date, "yyyy");

    sections[year] = sections[year] || [];
    sections[year].push(chat);
  }

  const ordered = orderBy(
    entries(sections).filter(([, items]) => items.length),
    ([, section]) => section?.[0]?.updatedAt,
    "desc",
  );

  return ordered.map(([title, items]) => ({
    title: startCase(title),
    items,
  }));
}
