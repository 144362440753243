import * as yup from "yup";
import { values } from "lodash";
import { TicketCategory } from "~/integrations/zendesk";

export const validationSchema = yup
  .object({
    name: yup
      .string()
      .required("support.form.fields.name.required")
      .trim()
      .max(200),
    email: yup
      .string()
      .email("support.form.fields.email.invalid")
      .required("support.form.fields.email.required")
      .trim(),
    category: yup
      .string()
      .oneOf(values(TicketCategory), "support.form.fields.category.invalid")
      .required("support.form.fields.category.required")
      .trim(),
    message: yup
      .string()
      .required("support.form.fields.message.required")
      .max(2000, "support.form.fields.message.max")
      .trim(),
    chatId: yup.string().optional(),
  })
  .required();
