export enum FileStatus {
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
  ERROR = "ERROR",
  PROCESSED = "PROCESSED",
}

export enum FileTypeImage {
  PNG = "image/png",
  JPG = "image/jpg",
  JPEG = "image/jpeg",
}

export type FileType = FileTypeImage | string;

export interface File {
  id: string;
  userId: string;
  organisationId: string | null;
  status: FileStatus;
  error: FileError | null;
  fileName: string;
  fileType: FileType;
  updatedAt: number;
  gcsPath: string;
  idempotencyKey: string | null;
}

export interface FileError {
  file: string;
  errorCode: string;
}

export enum FileErrorCode {
  NONE = "",
  ERR_PROCESSING_FILE = "300",
  ERR_FILE_CORRUPTED = "301",
}
