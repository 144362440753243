import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "ui";
import React from "react";
import { ThemePreference, useTheme } from "~/context/ThemeProvider";
import { useUser } from "~/context/UserContext";
import { useApplication } from "~/context/ApplicationContext";
import { Text } from "~/components/Text";

export function ThemeNotificationModal() {
  const { setTheme } = useTheme();
  const { user, setThemePreference } = useUser();
  const { features } = useApplication();

  if (!features.theme.notification) {
    return null;
  }

  async function onSelect(theme: ThemePreference) {
    setTheme(theme);
    await setThemePreference(theme);
  }

  return (
    <Dialog open={user && !user?.theme}>
      <DialogContent className="text-center" hideClose>
        <DialogHeader>
          <DialogTitle className="text-center">
            <Text
              id="notifications.light-mode.title"
              className="bg-noa-text bg-clip-text text-opacity-0 text-white font-medium text-2xl"
            />
          </DialogTitle>
        </DialogHeader>

        <Text
          id="notifications.light-mode.message"
          className="text-content-secondary font-light text-sm prose dark:prose-invert pb-6"
          multiline
        />

        <DialogFooter className="grid sm:grid-cols-2 gap-2">
          <Button variant="outline" onClick={() => onSelect("dark")}>
            <Text id="notifications.light-mode.cancel" />
          </Button>
          <Button onClick={() => onSelect("light")}>
            <Text id="notifications.light-mode.confirm" />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
