interface Props {
  className?: string;
}

export function NoaIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 64 64"
      fill="none"
    >
      <rect
        width="64"
        height="64"
        rx="32"
        fill="url(#paint0_linear_124_8228)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_124_8228"
          x1="5.25"
          y1="8.84712e-07"
          x2="62.9299"
          y2="5.40313"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7255C5" />
          <stop offset="1" stopColor="#6934FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
