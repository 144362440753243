/* eslint-disable react-hooks/exhaustive-deps */
// TODO: This will need to be part of a bigger refactoring work.
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import {
  arrayUnion,
  doc,
  runTransaction,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

import {
  Chat,
  ChatMessage,
  ChatStatus,
  RatingType,
  Role,
} from "@noa/types/Chat";

import { v4 as uuidv4 } from "uuid";
import { useAuthState } from "react-firebase-hooks/auth";
import { useChats } from "~/context/ChatsContext";
import { db } from "~/integrations/firebase/firestore";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { createTicket, TicketCategory } from "~/integrations/zendesk";
import { auth } from "~/integrations/firebase/auth";
import { useOrganisation } from "./OrganisationContext";

interface ChatContextProps {
  chat: Chat;
  isReady: boolean;
  isLoading: boolean;
  messages: ChatMessage[];

  addMessage(
    message: string,
    previousMessageId?: string,
    fileIds?: string[],
  ): Promise<void>;

  rateMessage(
    messageId: string,
    rating: RatingType,
    feedbackText: string | null,
  ): Promise<void>;

  attachFile(fileId: string): Promise<void>;

  regenerateMessage(): Promise<void>;

  stopGeneration(): Promise<void>;

  onRename(): void;

  onDelete(): void;
}

export const ChatContext = createContext<ChatContextProps>(undefined as never);

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }: PropsWithChildren) => {
  const { id } = useParams<{ id: string }>();
  const { organisation } = useOrganisation();
  const { log } = useAnalytics();
  const [authUser] = useAuthState(auth);

  const { chats, reference, setDeleteChatId, setRenameChatId } = useChats();

  const chat = chats.find((c: any) => c.id === id)!;

  const addMessage = async (
    message: string,
    previousMessageId?: string | undefined,
    fileIds?: string[],
  ) => {
    const messageId = uuidv4();

    log({
      type: "chat_message_sent",
      payload: {
        chatId: chat.id,
        organisationId: chat.organisationId,
        organisationName: organisation.name,
        messageId,
        messageLength: message.length,
        chatMessagesLength: chat.messages.length,
      },
    });

    const ref = doc(reference, id);

    await updateDoc(ref, {
      updatedAt: serverTimestamp(),
      status: ChatStatus.REQUESTED,
      messages: arrayUnion({
        id: messageId,
        content: message,
        role: Role.USER,
        updatedAt: new Date(),
        previousMessageId: previousMessageId ?? null,
        model: null,
        platform: null,
        errorMessage: null,
        idempotencyKey: null,
        files: fileIds || [],
      }),
    });
  };

  const rateMessage = async (
    messageId: string,
    rating: RatingType,
    feedbackText: string | null,
  ) => {
    const ref = doc(reference, id);
    const messageIndex = chat.messages.findIndex(
      (message) => message.id === messageId,
    );

    await runTransaction(db, async (transaction) => {
      const messages = (await transaction.get(ref)).get("messages");

      messages[messageIndex] = {
        ...messages[messageIndex],
        rating: {
          value: rating,
          updatedAt: new Date(),
          feedbackText,
        },
      };

      transaction.update(ref, { messages });
    });

    if (authUser && feedbackText?.trim()) {
      await createTicket({
        subject: "Chat feedback",
        name: authUser.displayName!,
        email: authUser.email!,
        message: `A user provided feedback to a message:\n\n${feedbackText}`,
        category: TicketCategory.feedback,
        metadata: {
          Chat: `https://noa.chat/chat/${id}`,
          "Message ID": messageId,
        },
      });
    }
  };

  const attachFile = async (fileId: string) => {
    const ref = doc(reference, id);

    return runTransaction(db, async (transaction) => {
      const currentChat = await transaction.get(ref);
      const files = currentChat.get("files") ?? {};

      // Attach file to chat
      transaction.update(ref, {
        updatedAt: serverTimestamp(),
        files: {
          ...files,
          [fileId]: {
            updatedAt: serverTimestamp(),
          },
        },
      });
    });
  };

  const regenerateMessage = async () => {
    const ref = doc(reference, id);

    await updateDoc(ref, {
      status: ChatStatus.REGENERATE,
      updatedAt: serverTimestamp(),
    });
  };

  const messages = useMemo(() => {
    if (!chat?.messages?.length) {
      return [];
    }

    const filtered = chat.messages.filter(
      (message) => message.role !== Role.SYSTEM,
    );

    const lastMessage = filtered[filtered.length - 1];

    if (!lastMessage) {
      return [];
    }

    const currentMessages = [lastMessage];

    // Build a list of messages by following the previous messages from the latest message
    while (currentMessages[currentMessages.length - 1].previousMessageId) {
      const { previousMessageId } = currentMessages[currentMessages.length - 1];

      const previous = filtered.find(
        (message) => message.id === previousMessageId,
      );

      if (!previous) {
        break;
      }

      currentMessages.push(previous);
    }

    return currentMessages.reverse();
  }, [chat?.messages]);

  const stopGeneration = useCallback(async () => {
    const ref = doc(reference, id);

    await updateDoc(ref, {
      status: ChatStatus.READY,
      updatedAt: serverTimestamp(),
    });
  }, [id, reference]);

  const value = useMemo(() => {
    return {
      chat,
      messages,
      addMessage,
      rateMessage,
      attachFile,
      regenerateMessage,
      stopGeneration,
      isReady: chat?.status === ChatStatus.READY,
      isLoading: [
        ChatStatus.REQUESTED,
        ChatStatus.REGENERATE,
        ChatStatus.STREAMING,
      ].includes(chat?.status),

      onRename() {
        setRenameChatId(chat.id);
      },

      onDelete() {
        setDeleteChatId(chat.id);
      },
    };
  }, [
    addMessage,
    attachFile,
    chat,
    messages,
    rateMessage,
    regenerateMessage,
    stopGeneration,
  ]);

  return (
    <ChatContext.Provider value={value}>
      {chat && children}
    </ChatContext.Provider>
  );
};
