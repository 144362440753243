import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { differenceInSeconds } from "date-fns";
import { keys, throttle } from "lodash";
import { Chat, ChatStatus, Role } from "@noa/types/Chat";
import { useChat } from "~/context/ChatContext";
import { useInterval } from "~/hooks/useInterval";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { useFiles } from "~/context/FilesContext";
import { ChatInput } from "~/components/ChatInput/ChatInput";
import { ChatMessages } from "~/components/ChatMessages";
import { ChatHeader } from "~/components/ChatHeader/ChatHeader";
import { useOrganisation } from "~/context/OrganisationContext";
import { ChatError } from "~/components/ChatError/ChatError";
import {
  CONTACT_URL,
  getErrorMessage,
} from "~/components/ChatError/getErrorMessage";

export default function ChatPage() {
  const { chat, attachFile, addMessage, messages, stopGeneration } = useChat();
  const messagesRef = useRef<HTMLDivElement>(null);
  const [showFileModal, setShowFileModal] = useState(false);
  const [imageInChat, setImageInChat] = useState(false);
  const { log } = useAnalytics();
  const { files } = useFiles();
  const { organisation } = useOrganisation();

  const shouldScrollToBottom = useRef(true);
  const scrollY = useRef(0);

  useEffect(() => {
    setShowFileModal(false);
  }, [chat.id]);

  const getLastMessageId = useCallback(
    () => messages[messages.length - 1]?.id,
    [messages],
  );

  const getSecondsSinceLastUpdate = useCallback(
    (_chat: Chat) => {
      if (_chat.status === ChatStatus.READY || !_chat.updatedAt) return 0;
      return differenceInSeconds(new Date(), chat.updatedAt.toDate());
    },
    [chat.updatedAt],
  );

  useEffect(() => {
    const currentMessageRef = messagesRef.current;
    const onScroll = throttle((event: Event) => {
      const element = event.target as HTMLDivElement;
      shouldScrollToBottom.current =
        element.scrollTop >= element.scrollHeight - element.clientHeight - 100;
      scrollY.current = element.scrollTop;
    }, 100);

    if (currentMessageRef?.scrollTo) {
      currentMessageRef.scrollTo({ top: currentMessageRef.scrollHeight });
      currentMessageRef.addEventListener("scroll", onScroll);
    }

    return () => currentMessageRef?.removeEventListener("scroll", onScroll);
  }, [chat.id]);

  const scrollToBottom = useCallback((force: boolean = false) => {
    if (!messagesRef.current?.scrollTo) return;
    if (!shouldScrollToBottom.current && !force) return;
    messagesRef.current.scrollTo({
      behavior: "smooth",
      top: messagesRef.current.scrollHeight,
    });
  }, []);

  const throttledScrollToBottom = useMemo(
    () => throttle(scrollToBottom, 300),
    [scrollToBottom],
  );

  const [secondsSinceLastUpdate, setSecondsSinceLastUpdate] = useState(0);
  useInterval(
    () => setSecondsSinceLastUpdate(getSecondsSinceLastUpdate(chat)),
    1000,
  );

  const errorMessage = useMemo(() => {
    if (!chat) return null;
    const { status, error, updatedAt } = chat;
    if (status === ChatStatus.ERROR) {
      return error
        ? getErrorMessage(error.errorCode)
        : `Apologies, Noa couldn't get a response. Please try again or [contact the Noa team](${CONTACT_URL}).`;
    }
    if (status === ChatStatus.READY || !updatedAt) return null;
    if (secondsSinceLastUpdate < 30) return null;
    return secondsSinceLastUpdate < 300
      ? "It looks like Noa is taking some time to respond."
      : "Apologies, Noa couldn't get a response.";
  }, [chat, secondsSinceLastUpdate]);

  useEffect(() => {
    if (errorMessage) {
      log({
        type: "chat_error_shown",
        payload: {
          chatId: chat.id,
          organisationId: chat.organisationId,
          errorMessage,
          latestMessageId: getLastMessageId(),
          organisationName: organisation.name,
        },
      });
    }
  }, [
    chat.id,
    chat.organisationId,
    errorMessage,
    getLastMessageId,
    log,
    organisation.name,
  ]);

  const isLoading = [
    ChatStatus.REQUESTED,
    ChatStatus.REGENERATE,
    ChatStatus.STREAMING,
  ].includes(chat.status);

  const displayMessages = useMemo(() => {
    const filtered =
      messages?.filter((message) => message.role !== Role.SYSTEM) ?? [];
    if (chat.status === ChatStatus.REGENERATE) filtered.pop();
    return !filtered?.length || !isLoading || errorMessage
      ? filtered
      : filtered;
  }, [messages, chat.status, isLoading, errorMessage]);

  const isReady = chat.status === ChatStatus.READY;

  const activeFile = useMemo(() => {
    const ids = keys(chat.files);
    return files.find((file) => file.id === ids[0]);
  }, [chat.files, files]);

  const onUploadFile = useCallback(() => {
    log({
      type: "chat_file_upload_clicked",
      payload: {
        chatId: chat.id,
        organisationId: chat.organisationId,
        organisationName: organisation.name,
      },
    });
    setShowFileModal((prev) => !prev);
  }, [chat.id, chat.organisationId, log, organisation.name]);

  const onFileUploaded = useCallback(
    async (fileId: string) => {
      log({
        type: "chat_file_uploaded",
        payload: {
          chatId: chat.id,
          organisationId: chat.organisationId,
          fileId,
        },
      });
      await attachFile(fileId);
    },
    [attachFile, chat.id, chat.organisationId, log],
  );

  const onStopGeneration = useCallback(async () => {
    log({
      type: "chat_message_stop_generation",
      payload: { chatId: chat.id },
    });
    await stopGeneration();
  }, [chat.id, log, stopGeneration]);

  useEffect(() => {
    throttledScrollToBottom();
  }, [displayMessages, throttledScrollToBottom]);

  const isImageDisplayedInMessages = useCallback(
    () => messages.some((message) => message.files && message.files.length > 0),
    [messages],
  );

  const extractFileIds = useCallback(() => {
    if (!imageInChat && activeFile && !isImageDisplayedInMessages()) {
      setImageInChat(true);
      return [activeFile.id];
    }
    return [];
  }, [activeFile, imageInChat, isImageDisplayedInMessages]);

  const submitMessage = useCallback(
    async (message: string) => {
      const fileIds = extractFileIds();
      const previousMessageId = getLastMessageId();
      await addMessage(message, previousMessageId, fileIds);
      scrollToBottom(true);
    },
    [addMessage, extractFileIds, getLastMessageId, scrollToBottom],
  );

  const hasError = chat.status === ChatStatus.ERROR;

  return (
    <div
      className="flex-1 flex flex-col"
      data-page="chat"
      data-chat-status={chat.status}
    >
      <ChatHeader />

      <div className="overflow-auto flex-1" ref={messagesRef}>
        {chat && (
          <ChatMessages
            messages={displayMessages}
            scrollToBottom={throttledScrollToBottom}
          />
        )}
      </div>

      <div className="px-5 pb-5">
        {!hasError ? (
          <ChatInput
            key={chat.id}
            showFileModal={showFileModal}
            activeFile={activeFile}
            disabled={!isReady}
            onSubmit={submitMessage}
            onUploadFile={onUploadFile}
            onUploadFileSuccess={onFileUploaded}
            onStopGeneration={onStopGeneration}
            showActions={!chat.taskId}
            isGenerating={[
              ChatStatus.REQUESTED,
              ChatStatus.REGENERATE,
              ChatStatus.STREAMING,
            ].includes(chat.status)}
          />
        ) : (
          <ChatError />
        )}
      </div>
    </div>
  );
}
