import { Check } from "iconoir-react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { XIcon } from "~/components/Icons/XIcon";
import { useNotifications } from "~/context/NotificationsContext";
import {
  ClearButton,
  Container,
  CustomLink,
  Icon,
  Notification,
} from "./styles";

export function Notifications() {
  const { notifications, clearNotification } = useNotifications();

  return (
    <Container>
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          direction="down"
          delay={0}
          speedIn={0.3}
          speedOut={0.8}
          type={notification.type}
          timeout={notification.timeout}
        >
          <Icon>{notification.type === "success" && <Check />}</Icon>
          {/* @ts-ignore */}
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              // @ts-ignore
              a: CustomLink,
            }}
          >
            {`${notification.message} ${
              notification.link?.to
                ? `[${notification.link.text}](${notification.link.to})`
                : ""
            }`}
          </ReactMarkdown>
          <ClearButton onClick={() => clearNotification(notification.id)}>
            <XIcon />
          </ClearButton>
        </Notification>
      ))}
    </Container>
  );
}
