import { Organisation } from "@noa/types";
import { ColumnDef } from "@tanstack/react-table";

import { DataTableColumnHeader } from "ui";

export const columns: ColumnDef<
  Organisation & {
    activeUsersCount: number;
    allowedEmailsCount: number;
    allowedDomainsCount: number;
  }
>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="NAME" />
    ),
    cell: ({ row }) => <div>{row.getValue("name")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "allowedDomainsCount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ALLOWED DOMAINS" />
    ),
    cell: ({ row }) => <div>{row.getValue("allowedDomainsCount")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "allowedEmailsCount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ALLOWED EMAILS" />
    ),
    cell: ({ row }) => <div>{row.getValue("allowedEmailsCount")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "activeUsersCount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ACTIVE USERS" />
    ),
    cell: ({ row }) => <div>{row.getValue("activeUsersCount")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
];
