import { Outlet } from "react-router-dom";
import { AdminHeader } from "~/components/AdminHeader/AdminHeader";

export default function AdminLayout() {
  return (
    <div className="flex-1 flex flex-col bg-background">
      <AdminHeader />

      <main className="px-6 py-10 overflow-auto">
        <Outlet />
      </main>
    </div>
  );
}
