import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { Organisation, OrganisationPrivate } from "@noa/types";

import { Navigate, useParams } from "react-router-dom";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { useAdminOrganisations } from "./AdminOrganisationsContext";

interface AdminOrganisationContextProps {
  organisation: Organisation;
  organisationPrivate: OrganisationPrivate;

  addAllowedEmails: (emails: string[]) => Promise<void>;
  removeAllowedEmails: (emails: string[]) => Promise<void>;
  addAllowedDomains: (domains: string[]) => Promise<void>;
}

export const AdminOrganisationContext =
  createContext<AdminOrganisationContextProps>(undefined as never);

export const useAdminOrganisation = () => useContext(AdminOrganisationContext);

export const AdminOrganisationProvider = ({ children }: PropsWithChildren) => {
  const { id } = useParams<{ id: string }>();

  const { organisations, organisationsPrivate, organisationsPrivateReference } =
    useAdminOrganisations();

  const organisation = useMemo(() => {
    return organisations.find((org) => org.id === id);
  }, [organisations, id]);

  const organisationPrivate = useMemo(() => {
    return (
      organisationsPrivate.find((org) => org.id === id) ?? {
        id: id!,
        allowedDomains: [],
        allowedEmails: [],
      }
    );
  }, [organisationsPrivate, id]);

  const addAllowedEmails = useCallback(
    async (emails: string[]) => {
      const ref = doc(organisationsPrivateReference, id);
      await updateDoc(ref, {
        allowedEmails: arrayUnion(
          ...emails.map((email) => email.toLowerCase()),
        ),
      });
    },
    [id, organisationsPrivateReference],
  );

  const removeAllowedEmails = useCallback(
    async (emails: string[]) => {
      const ref = doc(organisationsPrivateReference, id);
      await updateDoc(ref, {
        allowedEmails: arrayRemove(
          ...emails.map((email) => email.toLowerCase()),
        ),
      });
    },
    [id, organisationsPrivateReference],
  );

  const addAllowedDomains = useCallback(async (domains: string[]) => {
    throw new Error(`Not Implemented! ${domains} not stored.`);
  }, []);

  const value = useMemo(() => {
    if (organisation === undefined) {
      return null;
    }

    return {
      organisation,
      organisationPrivate,
      addAllowedEmails,
      addAllowedDomains,
      removeAllowedEmails,
    };
  }, [
    organisation,
    organisationPrivate,
    addAllowedEmails,
    addAllowedDomains,
    removeAllowedEmails,
  ]);

  if (value === null) {
    return <Navigate to="/admin" />;
  }

  return (
    <AdminOrganisationContext.Provider value={value}>
      {children}
    </AdminOrganisationContext.Provider>
  );
};
