import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  User,
  UserCredential,
} from "firebase/auth";
import * as Sentry from "@sentry/react";
import { useAuthState } from "react-firebase-hooks/auth";
import { styled } from "styled-components";
import { UserRole } from "@noa/types";
import { auth } from "~/integrations/firebase/auth";
import { useFeatureFlags } from "~/context/FeatureFlagProvider";

interface AuthContextProps {
  authUser?: User | null;
  loading: boolean;
  isLoggedIn: boolean;
  roles: UserRole[];

  signInWithOauth(type: OAuthProviderType): Promise<UserCredential>;

  signOut(): Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>(undefined as never);

export const useAuth = () => useContext(AuthContext);

const OAUTH_PROVIDERS = {
  google: new GoogleAuthProvider(),
};

type OAuthProviderType = keyof typeof OAUTH_PROVIDERS;

/**
 * This component is responsible for reporting on the authentication state of the app.
 */
const LoggedInState = styled.div`
  display: none;
`;

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [authUser, loading] = useAuthState(auth);

  useEffect(() => {
    return onAuthStateChanged(auth, async (user) => {
      if (!user) {
        setRoles([]);
        Sentry.setUser(null);
        return;
      }

      const result = await user.getIdTokenResult(true);
      const userRoles = (result.claims?.roles ?? []) as UserRole[];

      if (import.meta.env.VITE_AUTH_USER_ROLES) {
        const envRoles = import.meta.env.VITE_AUTH_USER_ROLES.split(",");
        userRoles.push(...(envRoles as UserRole[]));
      }

      Sentry.setUser({
        id: user.uid,
        roles: userRoles,
      });

      setRoles(userRoles);
    });
  }, []);

  const featureFlags = useFeatureFlags();

  async function signInWithOauth(type: OAuthProviderType) {
    const provider = OAUTH_PROVIDERS[type];

    if (!provider) {
      throw new Error(`No provider found for type ${type}`);
    }

    return signInWithPopup(auth, provider);
  }

  async function signOut() {
    return auth.signOut();
  }

  useEffect(() => {
    if (!authUser) {
      featureFlags.setUser(null);
      return;
    }

    featureFlags.setUser({
      id: authUser.uid,
      name: authUser.displayName ?? undefined,
      email: authUser.email ?? undefined,
      avatar: authUser.photoURL ?? undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, authUser?.uid, featureFlags.setUser]);

  const isLoggedIn = !!authUser;

  const value = useMemo(() => {
    return {
      authUser,
      loading,
      isLoggedIn,
      signInWithOauth,
      signOut,
      roles,
    };
  }, [authUser, isLoggedIn, loading, roles]);

  return (
    <AuthContext.Provider value={value}>
      {children}
      {isLoggedIn && <LoggedInState data-testid="user-logged-in" />}
    </AuthContext.Provider>
  );
};
