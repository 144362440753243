import React, { useEffect } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ChatPage from "~/routes/Chat/ChatPage";
import MainLayout from "~/routes/Layout/MainLayout";
import ChatIntroPage from "~/routes/Chat/ChatIntroPage";
import { ChatProvider } from "~/context/ChatContext";
import { useHeader } from "~/context/HeaderContext";

export default function ChatRoute() {
  const header = useHeader();

  useEffect(() => {
    header.setVisible(false);
  }, [header]);

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<ChatIntroPage />} />

        <Route
          path="chat"
          element={
            <ChatProvider>
              <Outlet />
            </ChatProvider>
          }
        >
          <Route path="" element={<ChatIntroPage />} />
          <Route path=":id" element={<ChatPage />} />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}
