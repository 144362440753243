import { useEffect, useState } from "react";

const SMALL_DEVICE_MAX_SIZE = 950;

const getIsSmallDevice = () => window.innerWidth <= SMALL_DEVICE_MAX_SIZE;

const useIsSmallDevice = () => {
  const [isSmallDevice, setIsSmallDevice] = useState(getIsSmallDevice());

  useEffect(() => {
    const onResize = () => {
      setIsSmallDevice(getIsSmallDevice());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return { isSmallDevice };
};

export default useIsSmallDevice;
