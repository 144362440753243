import "./integrations/sentry";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { TextProvider } from "@noa/locales";
import { TooltipProvider } from "ui";
import { strings as en } from "~/locales/en";
import { AuthContextProvider } from "./context/AuthContext";
import { HeaderProvider } from "~/context/HeaderContext";
import { ApplicationProvider } from "~/context/ApplicationContext";
import { LayoutContextProvider } from "./context/LayoutContext";
import { AnalyticsProvider } from "~/context/AnalyticsProvider";
import { NotificationsProvider } from "~/context/NotificationsContext";
import { initialiseLaunchDarkly } from "~/integrations/launch-darkly";

import { ThemeProvider } from "~/context/ThemeProvider";
import { FeatureFlagProvider } from "~/context/FeatureFlagProvider";
import App from "./App";

import "ui/styles/global.css";
import "./index.css";

const root = createRoot(document.getElementById("root")!);

async function load() {
  const LaunchDarklyProvider = await initialiseLaunchDarkly();

  root.render(
    <StrictMode>
      <LaunchDarklyProvider>
        <TextProvider value={en}>
          <ThemeProvider>
            <BrowserRouter>
              <FeatureFlagProvider>
                <ApplicationProvider>
                  <NotificationsProvider>
                    <AnalyticsProvider>
                      <AuthContextProvider>
                        <LayoutContextProvider>
                          <HeaderProvider>
                            <TooltipProvider>
                              <App />
                            </TooltipProvider>
                          </HeaderProvider>
                        </LayoutContextProvider>
                      </AuthContextProvider>
                    </AnalyticsProvider>
                  </NotificationsProvider>
                </ApplicationProvider>
              </FeatureFlagProvider>
            </BrowserRouter>
          </ThemeProvider>
        </TextProvider>
      </LaunchDarklyProvider>
    </StrictMode>,
  );
}

load().then();
