import React from "react";
import { CaretUp, Check, Trash } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { File as FileIcon } from "@phosphor-icons/react/dist/csr/File";
import { Button, Tooltip, TooltipContent, TooltipTrigger } from "ui";
import { File } from "@noa/types";
import { FileUploadModal } from "~/components/FileUpload";

interface ChatFilesActionsProps {
  activeFile?: File;

  onToggle(): void;

  onSuccess(fileId: string): Promise<void>;

  onRemoveFile?(): void;
}

export function ChatFiles({
  activeFile,
  onToggle,
  onSuccess,
  onRemoveFile,
}: ChatFilesActionsProps) {
  const navigate = useNavigate();

  if (!activeFile) {
    return (
      <FileUploadModal
        onClose={() => onToggle()}
        onNewChat={() => navigate("/")}
        onSuccess={onSuccess}
      />
    );
  }

  return (
    <div
      className="border p-4 rounded-lg space-x-3 flex items-center"
      data-testid="chat-active-file"
      data-file-id={activeFile.id}
    >
      <FileIcon className="h-10 w-10 text-icons-50" />

      <div className="space-y-2 leading-tight flex-1 grid grid-cols-1">
        <div className="inline-flex">
          <div className="font-medium truncate text-content-primary">
            {activeFile.fileName}
          </div>
          <Check className="text-check h-5 ml-1" />
        </div>
        <div className="text-sm text-content-secondary">
          This file is active in this chat
        </div>
      </div>

      <div className="ml-auto">
        {onRemoveFile ? (
          <Tooltip>
            <TooltipTrigger asChild onClick={onRemoveFile}>
              <Button size="icon" variant="ghost" type="button">
                <Trash className="w-5 h-5" />
              </Button>
            </TooltipTrigger>

            <TooltipContent>
              <span>DELETE</span>
            </TooltipContent>
          </Tooltip>
        ) : (
          <Tooltip>
            <TooltipTrigger asChild onClick={onToggle}>
              <Button size="icon" variant="ghost" type="button">
                <CaretUp className="w-5 h-5" />
              </Button>
            </TooltipTrigger>

            <TooltipContent>
              <span>HIDE</span>
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
