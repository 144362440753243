import React, { useMemo, useState } from "react";
import { UserPersonaTask } from "@noa/types";
import PersonasSlideOutPanel from "~/components/PersonasSlideOutPanel/PersonasSlideOutPanel";
import { useTasks } from "~/context/TasksContext";
import PersonasHeader from "~/components/PersonasHeader/PersonasHeader";
import PersonasDescription from "~/components/PersonasDescription/PersonasDescription";
import PersonasCard from "~/components/PersonasCard/PersonasCard";

const PersonasPage = () => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState<
    UserPersonaTask | undefined
  >(undefined);
  const { tasks, loading } = useTasks();

  const personaTasks = useMemo(() => tasks["user-persona"]!, [tasks]);

  const handleSlideOutPanel = (persona: UserPersonaTask) => {
    setIsSliderOpen(!isSliderOpen);
    setSelectedPersona(persona);
  };

  if (loading) return;

  return (
    <section className="flex-1 flex flex-col relative">
      <PersonasHeader />

      <PersonasDescription />

      <div className="pt-24 flex w-full flex-col items-center">
        <span className="text-xs font-semibold text-content-secondary pb-6">
          SELECT A PERSONA BELOW
        </span>

        <div className="flex flex-row gap-4">
          {personaTasks.map((it) => (
            <PersonasCard
              key={it.id}
              persona={it}
              handleSlideOutPanel={handleSlideOutPanel}
            />
          ))}

          {selectedPersona && (
            <PersonasSlideOutPanel
              isOpen={isSliderOpen}
              setIsOpen={setIsSliderOpen}
              selectedPersona={selectedPersona}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default PersonasPage;
