import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { FeatureFlags } from "~/integrations/launch-darkly/LaunchDarklyContext";
import { useFeatureFlags } from "~/context/FeatureFlagProvider";

interface ApplicationContextProps {
  maintenanceEnabled: boolean;
  features: FeatureFlags;
}

export const ApplicationContext = createContext<ApplicationContextProps>(
  undefined as never,
);

export const useApplication = () => useContext(ApplicationContext);

export const ApplicationProvider = ({ children }: PropsWithChildren) => {
  const { features } = useFeatureFlags();

  const value = useMemo(() => {
    return {
      features,
      maintenanceEnabled: features.maintenance ?? false,
    };
  }, [features]);

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};
