import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useIsSmallDevice from "~/hooks/useIsSmallDevice";

interface LayoutContextProps {
  showSidebar: boolean;

  toggleSidebar(): void;

  setShowSidebar(show: boolean): void;
}

export const LayoutContext = createContext<LayoutContextProps>(
  undefined as never,
);

export const useLayout = () => useContext(LayoutContext);

export const LayoutContextProvider = ({ children }: PropsWithChildren) => {
  const { isSmallDevice } = useIsSmallDevice();
  const [showSidebar, setShowSidebar] = useState(!isSmallDevice);

  useEffect(() => {
    setShowSidebar(!isSmallDevice);
  }, [isSmallDevice]);

  const toggleSidebar = useCallback(
    () => setShowSidebar(!showSidebar),
    [showSidebar],
  );

  const value = useMemo(() => {
    return {
      showSidebar,
      toggleSidebar,
      setShowSidebar,
    };
  }, [showSidebar, toggleSidebar, setShowSidebar]);

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};
