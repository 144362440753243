import React from "react";
import { ExclamationMark } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import { Button, Card } from "ui";
import { Text } from "~/components/Text";

export function ChatError() {
  return (
    <Card className="space-y-6 max-w-chat mx-auto" data-testid="chat-error">
      <div className="flex items-start space-x-6">
        <div className="space-y-2 flex-1">
          <div className="text-content-secondary font-semibold text-lg">
            <Text id="chats.chat.error.title" />
          </div>
          <div className="text-content-tertiary font-normal text-sm">
            <Text id="chats.chat.error.message.unrecoverable" />
          </div>
        </div>

        <div className="bg-background w-12 h-12 shadow-sm rounded-sm flex items-center justify-center">
          <ExclamationMark className="text-destructive w-10 h-10" />
        </div>
      </div>

      <div className="grid sm:flex gap-2">
        <Button className="px-8" asChild data-testid="new-chat-button">
          <Link to="/">
            <Text id="chats.chat.error.actions.new-chat" />
          </Link>
        </Button>
        <Button
          className="px-8"
          variant="outline"
          asChild
          data-testid="contact-support-button"
        >
          <Link to="/support" target="_blank">
            <Text id="chats.chat.error.actions.contact-support" />
          </Link>
        </Button>
      </div>
    </Card>
  );
}
