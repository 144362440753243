import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "./components";
import { cn } from "./utils";
import { Markdown } from "./markdown";

type Action = "regenerate" | "copy" | "like" | "dislike";

interface ChatMessageProps {
  id?: string;
  avatar: string;
  sender: string;
  date: Date;
  content?: string;
  className?: string;
  actions?: React.ReactNode;
  theme?: "light" | "dark";
  isTyping?: boolean;
  files?: React.ReactNode;
  onType?(): void;
}

export function ChatMessage({
  id,
  avatar,
  sender,
  date,
  content,
  actions,
  className,
  onType,
  theme,
  isTyping,
  files
}: ChatMessageProps) {
  const [output, setOutput] = useState(content ?? "");

  const text = useRef("");
  const anim = useRef<number>();

  useEffect(() => {
    text.current = content ?? "";
  }, [content]);

  useEffect(() => {
    if (isTyping) {
      return;
    }

    if (output === text.current) {
      return;
    }

    if (anim.current) {
      cancelAnimationFrame(anim.current);
    }

    setOutput(text.current);
  }, [isTyping]);

  useEffect(() => {
    onType?.();
  }, [onType, output]);

  useEffect(() => {
    async function type() {
      setOutput((current) => {
        const next = text.current[current.length];

        return next ? current + next : current;
      });

      anim.current = requestAnimationFrame(type);
    }

    anim.current = requestAnimationFrame(type);

    return () => {
      if (!anim.current) {
        return;
      }

      cancelAnimationFrame(anim.current);
    };
  }, []);

  return (
    <div
      className={cn("grid grid-cols-[2rem,1fr] gap-x-3 gap-y-1", className)}
      data-testid="chat-message"
    >
      <Avatar className="h-8 w-8">
        <AvatarImage src={avatar} alt={sender} />
        <AvatarFallback>{sender?.slice(0, 1)}</AvatarFallback>
      </Avatar>

      <div className="self-center space-x-1">
        <span className="font-semibold opacity-80">{sender}</span>
        <span className="font-normal opacity-60 text-sm">
          {format(date, "H:mm aaaaa'm")}
        </span>
      </div>

      <div className="col-start-2" data-message-id={id}>
        <Markdown
          content={output}
          className={cn(isTyping && "indicator")}
          theme={theme}
        />
      </div>

      {files && (
        <div className="col-start-2 grid grid-flow-col gap-4 mr-auto mt-2.5">
          {files}
        </div>
      )}

      {actions && (
        <div className="col-start-2 grid grid-flow-col gap-4 mr-auto mt-2.5">
          {actions}
        </div>
      )}
    </div>
  );
}

export interface ChatMessageActionProps {
  title: string;
  showTooltip?: boolean;
}

export function ChatMessageAction({
  title,
  children,
  showTooltip,
}: PropsWithChildren<ChatMessageActionProps>) {
  return (
    <Tooltip delayDuration={500} open={showTooltip}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent side="top" align="center" sideOffset={6}>
        <p className="uppercase font-medium text-sm tracking-wide">{title}</p>
      </TooltipContent>
    </Tooltip>
  );
}
