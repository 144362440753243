import React from "react";
import { useForm } from "react-hook-form";
import { Button, Card, Textarea } from "ui";
import { X } from "@phosphor-icons/react";
import { Text } from "~/components/Text";

interface FormValues {
  feedback: string;
}

interface ChatMessageFeedbackProps {
  onClose(): void;

  onSubmit(feedback: string): Promise<void>;
}

export function ChatMessageFeedback({
  onClose,
  onSubmit,
}: ChatMessageFeedbackProps) {
  const { register, handleSubmit, formState } = useForm<FormValues>({});

  if (formState.isSubmitSuccessful) {
    return (
      <Card className="flex flex-col relative">
        <button
          type="button"
          className="absolute top-0 right-0 p-3 hover:text-icons text-icons-50"
          onClick={onClose}
          aria-label="Close"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="space-y-2">
          <div className="font-medium">
            <Text id="chats.chat.message.rating.submitted.title" />
          </div>
          <div className="text-content-tertiary text-sm">
            <Text id="chats.chat.message.rating.submitted.subtitle" />
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card className="relative">
      <form
        onSubmit={handleSubmit((values) => onSubmit(values.feedback))}
        className="flex flex-col"
      >
        <button
          type="button"
          className="absolute top-0 right-0 p-3 hover:text-icons text-icons-50"
          onClick={onClose}
          aria-label="Close"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="mb-6 space-y-2">
          <div className="font-medium">
            <Text id="chats.chat.message.rating.title" />
          </div>
          <div className="text-content-tertiary text-sm">
            <Text id="chats.chat.message.rating.subtitle" />
          </div>
        </div>

        <Textarea
          {...register("feedback", { required: true })}
          className="mb-4 min-h-28 max-h-56"
          placeholder="Type your answer here"
        />

        <div>
          <Button
            type="submit"
            className="min-w-[8.75rem] py-3 px-8 h-auto"
            disabled={
              !formState.isValid ||
              formState.isSubmitting ||
              formState.isSubmitted
            }
          >
            Submit
          </Button>
        </div>
      </form>
    </Card>
  );
}
