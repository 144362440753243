import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { cn } from "./utils";
import { Button, Tooltip, TooltipContent, TooltipTrigger } from "./components";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  oneDark,
  oneLight,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { Check, Copy } from "@phosphor-icons/react";

interface Props {
  content: string;
  className?: string;
  theme?: "dark" | "light";
}

async function copyContent(content: string) {
  await navigator.clipboard.write([
    new ClipboardItem({
      // Copy as plain text for when it's pasted into a plain text editor
      "text/plain": new Blob([content!], {
        type: "text/plain",
      }),
    }),
  ]);

  return true;
}

export function Markdown({ content, className, theme }: Props) {
  return (
    <ReactMarkdown
      className={cn("prose dark:prose-invert", className)}
      children={content}
      allowedElements={[
        "p",
        "b",
        "i",
        "strong",
        "li",
        "ol",
        "ul",
        "em",
        "a",
        "code",
        "img",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "table",
        "blockquote",
      ]}
      components={{
        code(props) {
          const { children } = props;

          if (props.inline) {
            return <code children={children} />;
          }

          // use className provided to get language. e.g. language-javascript => javascript
          const language = props.className
            ?.split(" ")
            .map((name) =>
              name.startsWith("language-")
                ? name.replace("language-", "")
                : undefined,
            )
            .find(Boolean);

          const code = String(children);

          return <Code code={code} language={language} theme={theme} />;
        },
      }}
      unwrapDisallowed
      linkTarget="_blank"
    />
  );
}

interface CodeProps {
  language?: string;
  code: string;
  theme?: "light" | "dark";
}

function Code({ language, code, theme }: CodeProps) {
  const style = theme === "dark" ? oneDark : oneLight;

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) {
      return;
    }

    const timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  return (
    <div className="border border-border rounded-md overflow-hidden">
      <div className="px-3 text-sm flex items-center h-10">
        <span className="font-medium text-content-secondary">{language}</span>

        <Tooltip open={copied ?? undefined}>
          <TooltipTrigger asChild onClick={() => copyContent(code)}>
            <Button
              size="icon"
              variant="ghost"
              className="ml-auto p-0 w-4 h-4 text-icons hover:bg-transparent"
              onClick={() => setCopied(true)}
            >
              {copied ? (
                <Check className="w-4 h-4 text-green-600" />
              ) : (
                <Copy className="w-4 h-4 text-icons" />
              )}
            </Button>
          </TooltipTrigger>

          <TooltipContent>
            <span className="uppercase font-medium text-sm tracking-wide">
              {copied ? "Copied to clipboard" : "Copy"}
            </span>
          </TooltipContent>
        </Tooltip>
      </div>

      <SyntaxHighlighter
        children={code.replace(/\n$/, "")}
        language={language}
        style={style}
        wrapLongLines
        customStyle={{
          margin: 0,
          borderRadius: 0,
        }}
      />
    </div>
  );
}
