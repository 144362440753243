import React from "react";
import { Warning } from "@phosphor-icons/react";
import { Text } from "@noa/locales";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "ui";
import { useNavigate } from "react-router-dom";

interface Props {
  onClose: (arg: boolean) => void;
}

export function ErrorChatModal({ onClose }: Props) {
  const navigator = useNavigate();

  return (
    <Dialog
      open
      onOpenChange={(open) => {
        if (!open) {
          onClose(open);
          navigator("/");
        }
      }}
    >
      <DialogContent data-testid="delete-chat-modal">
        <DialogHeader className="space-y-8">
          <Warning className="text-destructive w-10 h-10 mx-auto" />

          <div className="space-y-4 pb-4">
            <DialogTitle className="text-center text-xl">
              <Text id="chats.chat.error.title-chrome-extension" />
            </DialogTitle>

            <div className="text-sm text-content-tertiary text-center leading-loose">
              <Text id="chats.chat.error.message-chrome-extension" />
            </div>
          </div>
        </DialogHeader>

        <DialogFooter>
          <div className="gap-4 mx-auto">
            <DialogClose asChild>
              <Button
                data-testid="close-chat-error-button"
                className="px-10 w-[130px]"
              >
                OK
              </Button>
            </DialogClose>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
