/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { entries, orderBy, startCase } from "lodash";
import {
  format,
  isAfter,
  isThisYear,
  isToday,
  isYesterday,
  subDays,
} from "date-fns";
import { Chat } from "@noa/types";
import { getChatTitle } from "~/utils/chat";

export function getSidebarSections(chats: Chat[], currentChatId?: string) {
  const mapped = chats.map((chat) => {
    return {
      id: chat.id,
      link: `/chat/${chat.id}`,
      title: getChatTitle(chat),
      updatedAt: chat.updatedAt?.toDate(),
      hideActions: false,
    };
  });

  const sections: Record<string, (typeof mapped)[0][]> = {
    today: [],
    yesterday: [],
    last7days: [],
    last30days: [],
    older: [],
  };

  if (!currentChatId) {
    sections.today.splice(0, 0, {
      id: "new",
      link: "/",
      title: "New chat",
      updatedAt: new Date(),
      hideActions: true,
    });
  }

  const now = new Date();

  for (const chat of mapped) {
    if (!chat.updatedAt || isToday(chat.updatedAt)) {
      sections.today.push(chat);
      continue;
    }

    if (isYesterday(chat.updatedAt)) {
      sections.yesterday.push(chat);
      continue;
    }

    if (isAfter(chat.updatedAt, subDays(now, 7))) {
      sections.last7days.push(chat);
      continue;
    }

    if (isAfter(chat.updatedAt, subDays(now, 30))) {
      sections.last30days.push(chat);
      continue;
    }

    if (isThisYear(chat.updatedAt)) {
      const month = format(chat.updatedAt, "MMMM");

      sections[month] = sections[month] || [];
      sections[month].push(chat);

      continue;
    }

    const year = format(chat.updatedAt, "yyyy");

    sections[year] = sections[year] || [];
    sections[year].push(chat);
  }

  const ordered = orderBy(
    entries(sections),
    ([, section]) => section?.[0]?.updatedAt,
    "desc",
  );

  return ordered.map(([title, items]) => ({
    title: startCase(title),
    items,
  }));
}
