import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft } from "@phosphor-icons/react";
import { Button } from "ui";
import { useAdminOrganisation } from "~/context/AdminOrganisationContext";
import { AddEmailModal } from "~/components/AdminOrganisationAllowlist/AllowedEmailsList/AddEmailModal";
import { AllowedEmailsListTable } from "~/components/AdminOrganisationAllowlist/AllowedEmailsList/table";

export const AdminOrganisationDetailPage = () => {
  const { organisation, addAllowedEmails, organisationPrivate } =
    useAdminOrganisation();
  const [showAddNewUser, setShowAddNewUser] = useState(false);

  const handleSave = useCallback(
    async (emails: string[]) => {
      await addAllowedEmails(emails);
      setShowAddNewUser(false);
    },
    [addAllowedEmails],
  );

  return (
    <div className="space-y-8">
      <div className="flex items-center">
        <Link to="/admin" data-testid="back-button">
          <ArrowLeft className="text-icons-50 w-8 h-8 mr-3" />
        </Link>

        <div className="text-4xl text-content-secondary whitespace-nowrap text-ellipsis">
          {organisation.name}
        </div>

        <div className="flex flex-row-reverse ml-auto">
          <Button
            onClick={() => setShowAddNewUser(true)}
            className="px-8"
            data-testid="add-users"
          >
            Add new user
          </Button>
        </div>
      </div>

      <AllowedEmailsListTable
        data={organisationPrivate.allowedEmails.map((email) => ({
          email,
          permissions: [],
        }))}
      />

      <AddEmailModal
        isOpen={showAddNewUser}
        onClose={() => setShowAddNewUser(false)}
        onSave={handleSave}
      />
    </div>
  );
};
