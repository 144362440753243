import { ColumnDef } from "@tanstack/react-table";

import { DataTableColumnHeader } from "ui";
import { PermissionBadge } from "./PermissionBadge";
import { RemoveEmailButton } from "./RemoveEmailButton";

export const columns: ColumnDef<{ email: string; permissions: string[] }>[] = [
  {
    accessorKey: "email",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
    cell: ({ row }) => <div>{row.getValue("email")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "permissions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Permission" />
    ),
    cell: ({ row }) => (
      <div className="flex gap-2">
        {(row.getValue("permissions") as string[]).length ? (
          (row.getValue("permissions") as string[]).map((permission) => (
            <PermissionBadge
              key={permission}
              userRole={row.getValue("permissions")}
            />
          ))
        ) : (
          <PermissionBadge />
        )}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "remove",
    header: "Actions",
    cell: ({ row }) => <RemoveEmailButton email={row.getValue("email")} />,
    enableSorting: false,
    enableHiding: false,
  },
];
