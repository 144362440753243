import React, { PropsWithChildren } from "react";
import { Warning } from "@phosphor-icons/react";
import { Text } from "@noa/locales";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "ui";

interface Props {
  open?: boolean;

  title: string;
  confirm: string;

  onClose(): void;

  onConfirm(): void;
}

export function WarningModal({
  onClose,
  title,
  confirm,
  onConfirm,
  open,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogContent data-testid="delete-chat-modal">
        <DialogHeader className="space-y-8">
          <Warning className="text-destructive w-10 h-10 mx-auto" />

          <DialogTitle className="text-center text-xl">
            <Text id={title} />
          </DialogTitle>
        </DialogHeader>

        {children}

        <DialogFooter>
          <div className="grid gap-4 mx-auto">
            <DialogClose asChild>
              <Button
                onClick={onConfirm}
                data-testid="warning-modal-confirm-button"
                className="px-10"
                type="button"
              >
                <Text id={confirm} />
              </Button>
            </DialogClose>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
