import { Button } from "./components";
import Textarea, { TextareaAutosizeProps } from "react-textarea-autosize";
import { cn } from "./utils";
import { useForm } from "react-hook-form";
import { KeyboardEvent, PropsWithChildren, ReactNode } from "react";
import { PaperPlaneTilt, StopCircle } from "@phosphor-icons/react";

interface ChatInputProps extends Omit<TextareaAutosizeProps, "onSubmit"> {
  actions?: ReactNode;

  isGenerating?: boolean;

  onStopGeneration?(): void;

  onSubmit(message: string): Promise<void>;

  onUploadFile(): void;
}

export function ChatInput({
  className,
  placeholder,
  children,
  onUploadFile,
  actions,
  isGenerating,
  onStopGeneration,
  disabled,
  ...props
}: PropsWithChildren<ChatInputProps>) {
  const { handleSubmit, register, reset, formState } = useForm<{
    message: string;
  }>();

  const onSubmit = handleSubmit(async (values) => {
    if (disabled) {
      return;
    }

    await props.onSubmit(values.message);
    reset();
  });

  async function onKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      await onSubmit();
    }
  }

  return (
    <form
      onSubmit={onSubmit}
      className={cn(
        "border rounded-xl p-6 pb-4 space-y-4 shadow-light bg-input-background border-input",
        className,
      )}
    >
      <div className="flex space-x-3">
        <div className="text-lg drop-shadow-md shadow-pink-500">🧠</div>
        <Textarea
          className="flex-1 sm:min-h-[1.25rem] md:min-h-[4.25rem] outline-0 placeholder:text-placeholder bg-transparent max-h-[300px] resize-none"
          {...register("message", { required: true })}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          data-testid="chat-input"
        />
      </div>

      <div className="bg-input h-px w-full" />

      <div className="flex">
        {actions}

        {isGenerating ? (
          <Button
            className="ml-auto transition text-action-primary font-semibold"
            type="submit"
            variant="ghost"
            data-testid="chat-input-stop-button"
            onClick={onStopGeneration}
          >
            <StopCircle weight="bold" className="mr-2 h-5 w-5" />
            Stop Generating
          </Button>
        ) : (
          <Button
            className="ml-auto transition"
            type="submit"
            disabled={!formState.isValid || disabled}
            data-testid="chat-input-submit-button"
          >
            <PaperPlaneTilt weight="fill" className="mr-2 h-4 w-4" />
            Send message
          </Button>
        )}
      </div>

      {children}
    </form>
  );
}
