import React from "react";

import { styled } from "styled-components";
import { Text } from "@noa/locales";
import { FadeInOut } from "~/components/FadeInOut";

const Container = styled(FadeInOut)`
  display: flex;
  flex-direction: column;
  max-width: 45rem;
  padding: 15rem 2rem;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled(FadeInOut)`
  font-size: 1.625rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
`;

const Description = styled(FadeInOut)`
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.68rem;
  margin-bottom: 2rem;
`;

const MigrationPage: React.FC = () => {
  return (
    <Container>
      <Title>
        <Text id="maintenance.title" />
      </Title>

      <Description delay={100}>
        <Text id="maintenance.subtitle" multiline />
      </Description>
    </Container>
  );
};

export default MigrationPage;
