import React, { ElementType } from "react";
import { IconWeight } from "@phosphor-icons/react";
import { ChatMessageAction as Action } from "ui";
import { cn } from "~/lib/utils";

interface ChatMessageActionProps {
  title: string;
  className?: string;
  disabled?: boolean;
  icon: ElementType<{ className?: string; weight?: IconWeight }>;
  iconWeight?: IconWeight;
  showTooltip?: boolean;

  onClick(): void;
}

export function ChatMessageAction({
  className,
  title,
  showTooltip,
  icon: Icon,
  iconWeight,
  disabled,
  onClick,
}: ChatMessageActionProps) {
  return (
    <Action title={title} showTooltip={showTooltip}>
      <button
        className={cn("h-5 w-5 text-icons", className)}
        onClick={onClick}
        disabled={disabled}
        type="button"
        aria-label={title}
      >
        <Icon className="w-5 h-5" weight={iconWeight} />
      </button>
    </Action>
  );
}
