import { useApplication } from "~/context/ApplicationContext";

import MigrationPage from "~/routes/Maintenance/Maintenance";
import { Notifications } from "~/components/Notifications";
import AppRoutes from "~/routes/AppRoutes";

function App() {
  const { maintenanceEnabled } = useApplication();

  if (maintenanceEnabled) {
    return <MigrationPage />;
  }

  return (
    <>
      <Notifications />
      <AppRoutes />
    </>
  );
}

export default App;
