import { AdminOrganisations } from "~/components/AdminOrganisations/AdminOrganisations";

export const AdminOrganisationsPage = () => {
  return (
    <div className="space-y-8">
      <div className="text-4xl text-content-secondary whitespace-nowrap text-ellipsis h-12">
        Organisations
      </div>

      <AdminOrganisations />
    </div>
  );
};
