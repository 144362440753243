import React, { useCallback } from "react";
import { Organisation } from "@noa/types";
import { useNavigate } from "react-router-dom";
import { cn } from "~/lib/utils";
import { OrganisationsTable } from "./table/OrganisationsTable";

interface AdminOrganisationsProps {
  className?: string;
}

export function AdminOrganisations({ className }: AdminOrganisationsProps) {
  const navigate = useNavigate();
  const handleRowClick = useCallback(
    (organisation: Organisation) => {
      navigate(`/admin/organisations/${organisation.id}`);
    },
    [navigate],
  );

  return (
    <div className={cn("", className)}>
      <OrganisationsTable onRowClick={handleRowClick} />
    </div>
  );
}
