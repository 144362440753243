import { Header, NoaLogo } from "ui";
import { Link } from "react-router-dom";
import React from "react";
import { cn } from "~/lib/utils";
import { AccountMenu } from "~/components/AccountMenu/AccountMenu";

interface AdminHeaderProps {
  className?: string;
}

export function AdminHeader({ className }: AdminHeaderProps) {
  return (
    <Header className={cn("flex items-center", className)}>
      <Link to="/">
        <NoaLogo className="h-6" />
      </Link>

      <AccountMenu />
    </Header>
  );
}
