export async function copyMessage(messageId: string, content: string) {
  const element = document.querySelector(`div[data-message-id="${messageId}"]`);

  if (!element) {
    console.error("Could not find element to copy");
    return false;
  }

  await navigator.clipboard.write([
    new ClipboardItem({
      // Copy as HTML to retain formatting
      "text/html": new Blob([element.innerHTML!], {
        type: "text/html",
      }),

      // Copy as plain text for when it's pasted into a plain text editor
      "text/plain": new Blob([content!], {
        type: "text/plain",
      }),
    }),
  ]);

  return true;
}
