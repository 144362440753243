import React, { ElementType } from "react";
import { useText } from "@noa/locales";
import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerReturn,
} from "react-hook-form";
import { Input, Label } from "ui";
import { LocaleStrings } from "~/locales";
import { Text } from "~/components/Text";
import { cn } from "~/lib/utils";

interface FieldProps<V extends FieldValues> {
  control: Control<V>;
  name: Path<V>;
  label: LocaleStrings;
  placeholder: LocaleStrings;
  disabled?: boolean;
  className?: string;
  length?: number;
  as?: ElementType;
  children?: (props: UseControllerReturn<V, Path<V>>) => React.ReactNode;
}

export function Field<V extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  className,
  disabled,
  children,
  length,
  as: As,
}: FieldProps<V>) {
  const text = useText<LocaleStrings>();
  const props = useController({ control, name });

  const Component = As || Input;

  const renderProps = {
    ...props,
    field: {
      ...props.field,
      placeholder: text(placeholder),
      disabled,
    },
  };

  const showError = props.fieldState.isTouched || props.formState.isSubmitted;

  return (
    <Label className={cn("flex flex-col space-y-2", className)}>
      <div>
        <Text id={label} />
      </div>

      {children ? children(renderProps) : <Component {...renderProps.field} />}

      <div className="space-x-2">
        {length && (
          <span className="text-content-secondary opacity-50 text-sm">
            {props.field.value.length} / {length}
          </span>
        )}

        {props.fieldState.error && showError && (
          <span
            className="text-red-500 text-sm text-content-secondary inline-block"
            data-testid="field-error"
          >
            {text(props.fieldState.error.message as LocaleStrings)}
          </span>
        )}
      </div>
    </Label>
  );
}
