import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "ui";
import { DotsThree, Pencil, Trash } from "@phosphor-icons/react";
import React from "react";

interface ChatContextMenuProps {
  onRename(): void;

  onDelete(): void;
}

export function ChatContextMenu({ onRename, onDelete }: ChatContextMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="icon" variant="ghost" className="ml-auto shrink-0 mr-1">
          <DotsThree className="h-8 w-8 text-content-tertiary" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-48" side="bottom" align="end">
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={onRename}>
            <Pencil className="mr-3 h-5 w-5 text-icons-50" />
            <span className="text-content-primary">Rename chat</span>
          </DropdownMenuItem>

          <DropdownMenuItem onClick={onDelete}>
            <Trash className="mr-3 h-5 w-5 text-icons-50" />
            <span className="text-content-primary">Delete</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
