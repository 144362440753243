import React, { useEffect, useState } from "react";
import { NoaLogo, PageLayout } from "ui";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { Chat } from "@noa/types";
import { Link } from "react-router-dom";
import { useAuth } from "~/context/AuthContext";
import { SupportForm } from "~/components/SupportForm/SupportForm";
import { db } from "~/integrations/firebase/firestore";
import { getChatTitle } from "~/utils/chat";

export function SupportPage() {
  const { authUser, loading } = useAuth();
  const [chats, setChats] = useState<Chat[]>([]);

  useEffect(() => {
    if (!authUser?.uid) {
      setChats([]);
      return;
    }

    const q = query(
      collection(db, "chats"),
      where("userId", "==", authUser.uid),
      where("deleted", "==", false),
      orderBy("updatedAt", "desc"),
    );

    getDocs(q).then((snapshot) => {
      setChats(
        snapshot.docs.map((doc) => {
          const data = doc.data() as Chat;

          return {
            ...data,
            title: getChatTitle(data),
          };
        }),
      );
    });
  }, [authUser?.uid]);

  return (
    <PageLayout className="flex-1 flex flex-col overflow-hidden">
      <div className="p-6 bg-background z-10 flex">
        <Link to="/">
          <NoaLogo className="w-16" />
        </Link>
      </div>

      <div className="flex-1 overflow-auto py-12">
        <div className="container max-w-[55rem]">
          {!loading && <SupportForm user={authUser} chats={chats} />}
        </div>
      </div>
    </PageLayout>
  );
}
