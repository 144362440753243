import { Trash } from "lucide-react";
import React, { FC, useCallback, useState } from "react";
import { Button } from "ui";
import { useAdminOrganisation } from "~/context/AdminOrganisationContext";
import { RemoveEmailConfirmationModal } from "./RemoveEmailConfirmationModal";

export const RemoveEmailButton: FC<{ email: string }> = ({ email }) => {
  const { removeAllowedEmails } = useAdminOrganisation();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleConfirm = useCallback(async () => {
    await removeAllowedEmails([email]);
    setIsConfirmOpen(false);
  }, [email, removeAllowedEmails]);

  return (
    <>
      <RemoveEmailConfirmationModal
        isOpen={isConfirmOpen}
        email={email}
        onClose={() => setIsConfirmOpen(false)}
        onConfirm={handleConfirm}
      />
      <Button variant="ghost" onClick={() => setIsConfirmOpen(true)}>
        <Trash className="h-6" />
      </Button>
    </>
  );
};
