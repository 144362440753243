import React from "react";
import { CloudArrowUp } from "@phosphor-icons/react";
import { cn } from "~/lib/utils";

interface UploadScreenProps {
  inputProps: any;
  rootProps: any;
  isActive: boolean;
}

export function UploadScreen({
  isActive,
  inputProps,
  rootProps,
}: UploadScreenProps) {
  return (
    <div
      className={cn(
        "border-dashed border-2 rounded-lg flex flex-col items-center pt-8 pb-6 cursor-pointer transition",
        isActive && "border-purple-400",
      )}
      {...rootProps}
      data-testid="file-upload-drop"
    >
      <input data-testid="file-upload-input" {...inputProps} />

      <div className="flex flex-col items-center space-y-6">
        <CloudArrowUp className="h-10 w-10 text-icons-50" />

        <div className="space-y-2 text-center">
          <div className="text-lg text-content-secondary">
            Drag & drop your file here
          </div>
          <div className="text-action-primary font-medium">
            or select a file
          </div>
        </div>
      </div>

      <div className="text-center space-y-1 mt-10 text-sm">
        <p className="text-content-tertiary">
          NOA supports the following formats:
        </p>
        <p className="text-content-secondary">PDF, DOCX (Max 20Mb)</p>
      </div>
    </div>
  );
}
