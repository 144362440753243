export function NoaLogo({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 57 20"
      fill="none"
    >
      <path
        d="M5.13858 8.42371V19.6217H0V0.252206H4.89733V3.66961H5.11446C5.52458 2.54308 6.21213 1.65195 7.17712 0.996216C8.14212 0.332072 9.31217 0 10.6873 0C11.9739 0 13.0957 0.294241 14.0527 0.882723C15.0096 1.47121 15.7535 2.31189 16.2842 3.40479C16.815 4.48928 17.0804 5.78394 17.0804 7.28878V19.6217H11.9418V8.24716C11.9498 7.06179 11.6603 6.13703 11.0733 5.47289C10.4862 4.80034 9.67806 4.46406 8.64874 4.46406C7.95716 4.46406 7.346 4.61959 6.81525 4.93064C6.29255 5.2417 5.88243 5.69567 5.58489 6.29256C5.29539 6.88104 5.14662 7.59142 5.13858 8.42371Z"
        fill="currentColor"
      />
      <path
        d="M29.1619 20C27.2882 20 25.6679 19.5839 24.3008 18.7516C22.9417 17.9109 21.8923 16.7423 21.1525 15.2459C20.4127 13.7411 20.0428 11.9966 20.0428 10.0126C20.0428 8.01177 20.4127 6.26313 21.1525 4.76671C21.8923 3.26187 22.9417 2.09332 24.3008 1.26103C25.6679 0.420344 27.2882 0 29.1619 0C31.0356 0 32.652 0.420344 34.011 1.26103C35.3781 2.09332 36.4315 3.26187 37.1714 4.76671C37.9112 6.26313 38.2811 8.01177 38.2811 10.0126C38.2811 11.9966 37.9112 13.7411 37.1714 15.2459C36.4315 16.7423 35.3781 17.9109 34.011 18.7516C32.652 19.5839 31.0356 20 29.1619 20ZM29.1861 15.8386C30.0385 15.8386 30.7501 15.5864 31.3211 15.082C31.892 14.5691 32.3223 13.8714 32.6118 12.9887C32.9093 12.1059 33.0581 11.1013 33.0581 9.97478C33.0581 8.84826 32.9093 7.84363 32.6118 6.96091C32.3223 6.07818 31.892 5.38041 31.3211 4.86759C30.7501 4.35477 30.0385 4.09836 29.1861 4.09836C28.3256 4.09836 27.6019 4.35477 27.0148 4.86759C26.4358 5.38041 25.9976 6.07818 25.7 6.96091C25.4105 7.84363 25.2658 8.84826 25.2658 9.97478C25.2658 11.1013 25.4105 12.1059 25.7 12.9887C25.9976 13.8714 26.4358 14.5691 27.0148 15.082C27.6019 15.5864 28.3256 15.8386 29.1861 15.8386Z"
        fill="currentColor"
      />
      <path
        d="M46.566 19.9874C45.3839 19.9874 44.3305 19.773 43.4057 19.3443C42.4809 18.9071 41.7491 18.264 41.2103 17.4149C40.6796 16.5574 40.4142 15.4897 40.4142 14.2119C40.4142 13.1358 40.6032 12.232 40.9811 11.5006C41.3591 10.7692 41.8738 10.1807 42.5251 9.73518C43.1765 9.28962 43.9163 8.95334 44.7446 8.72636C45.5809 8.49937 46.4575 8.33964 47.3742 8.24716C48.4518 8.12947 49.3203 8.02018 49.9797 7.91929C50.6391 7.81 51.1176 7.65027 51.4151 7.4401C51.7127 7.22993 51.8614 6.91887 51.8614 6.50694V6.43127C51.8614 5.63262 51.6202 5.01471 51.1377 4.57755C50.6632 4.14039 49.9877 3.92182 49.1112 3.92182C48.1864 3.92182 47.4506 4.13619 46.9038 4.56494C46.3569 4.98529 45.9951 5.51492 45.8182 6.15385L41.0656 5.75032C41.3068 4.57335 41.7813 3.55612 42.4889 2.69861C43.1966 1.8327 44.1093 1.16856 45.2271 0.706177C46.3529 0.235392 47.6557 0 49.1353 0C50.1646 0 51.1497 0.126103 52.0906 0.378309C53.0395 0.630517 53.8799 1.02144 54.6116 1.55107C55.3515 2.08071 55.9345 2.76166 56.3607 3.59395C56.7869 4.41782 57 5.40563 57 6.55738V19.6217H52.1268V16.9357H51.982C51.6845 17.541 51.2864 18.0748 50.7879 18.5372C50.2893 18.9912 49.6902 19.3485 48.9906 19.6091C48.291 19.8613 47.4828 19.9874 46.566 19.9874ZM48.0376 16.2799C48.7935 16.2799 49.461 16.1244 50.04 15.8134C50.619 15.4939 51.0733 15.0652 51.403 14.5271C51.7328 13.9891 51.8976 13.3796 51.8976 12.6986V10.6431C51.7368 10.7524 51.5156 10.8533 51.2342 10.9458C50.9608 11.0298 50.6512 11.1097 50.3054 11.1854C49.9596 11.2526 49.6138 11.3157 49.268 11.3745C48.9222 11.425 48.6086 11.4712 48.3271 11.5132C47.724 11.6057 47.1973 11.7528 46.747 11.9546C46.2966 12.1564 45.9468 12.4296 45.6975 12.7743C45.4482 13.1105 45.3236 13.5309 45.3236 14.0353C45.3236 14.7667 45.5769 15.3258 46.0835 15.7125C46.5982 16.0908 47.2496 16.2799 48.0376 16.2799Z"
        fill="currentColor"
      />
    </svg>
  );
}
