import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, DocumentReference } from "firebase/firestore";

import { Organisation } from "@noa/types";
import { db } from "~/integrations/firebase/firestore";
import { useUser } from "./UserContext";
import { useAnalytics } from "./AnalyticsProvider";
import { useFeatureFlags } from "~/context/FeatureFlagProvider";

interface OrganisationContextProps {
  organisation: Organisation;
}

export const OrganisationContext = createContext<OrganisationContextProps>(
  undefined as never,
);

export const useOrganisation = () => useContext(OrganisationContext);

interface Props {}

export const OrganisationProvider = ({
  children,
}: PropsWithChildren<Props>) => {
  const { user } = useUser();
  const { setUserDetails, initialised } = useAnalytics();

  const { organisationId } = user;

  const reference = useMemo(() => {
    if (!organisationId) {
      console.error(
        `User [${user.userId}] does not have a valid organisationId.`,
        user,
      );

      throw new Error("User does not have a valid organisationId.");
    }

    return doc(
      db,
      "organisations",
      organisationId,
    ) as DocumentReference<Organisation>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  const [organisation, loading] = useDocumentData<Organisation>(reference);

  const { setOrganisation } = useFeatureFlags();

  useEffect(() => {
    setOrganisation({
      id: organisationId,
      name: organisation?.name,
    });

    setUserDetails({
      organisationId,
      organisationName: organisation?.name,
    });
  }, [
    setUserDetails,
    organisationId,
    initialised,
    organisation?.name,
    setOrganisation,
  ]);

  const value = useMemo(() => {
    return {
      organisation: organisation ?? {
        id: organisationId,
        name: "",
        emails: [],
      },
    };
  }, [organisation, organisationId]);

  if (loading) {
    return null;
  }

  return (
    <OrganisationContext.Provider value={value}>
      {children}
    </OrganisationContext.Provider>
  );
};
