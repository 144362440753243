import React, { HTMLAttributes } from "react";
import { cn } from "./utils";

function LoaderDot({ index }: { index: number }) {
  return (
    <div
      className="inline-block rounded-full opacity-50 bg-action-primary w-[4px] h-[4px] animate-loader"
      style={{
        animationDelay: `${index * 0.1}s`,
      }}
    />
  );
}

export function Loader({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} className={cn("flex space-x-1.5", className)}>
      <LoaderDot index={0} />
      <LoaderDot index={1} />
      <LoaderDot index={2} />
      <LoaderDot index={3} />
    </div>
  );
}
