import { connectStorageEmulator, getStorage } from "firebase/storage";
import { app } from "~/integrations/firebase/app";

export const storage = getStorage(
  app,
  `${import.meta.env.VITE_FIREBASE_PROJECT_ID}-firebase-storage`,
);

if (import.meta.env.VITE_FIREBASE_EMULATOR_ENABLED) {
  connectStorageEmulator(storage, "localhost", 9199);
}
