import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  collection,
  CollectionReference,
  doc,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";

import { Chat, ChatStatus, Role } from "@noa/types/Chat";
import { v4 as uuid } from "uuid";
import { db } from "~/integrations/firebase/firestore";
import { useAuth } from "./AuthContext";
import { useUser } from "~/context/UserContext";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { useOrganisation } from "./OrganisationContext";

export interface ChatsContextProps {
  chats: Chat[];
  loading: boolean;
  reference: CollectionReference<Chat>;

  createChatFromMessage(message: string, fileIds?: string[]): Promise<string>;

  createTaskFromMessage(
    message: string,
    { taskId }: TaskMessage,
  ): Promise<string>;

  deleteChatId?: string;
  renameChatId?: string;

  setDeleteChatId(id?: string): void;

  setRenameChatId(id?: string): void;

  deleteChat(id: string): Promise<string>;

  renameChat(id: string, title: string): Promise<string>;
}

export const ChatsContext = createContext<ChatsContextProps>(
  undefined as never,
);

export const useChats = () => useContext(ChatsContext);

const chatConverter = {
  toFirestore(chat: Chat) {
    return chat;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Chat {
    return snapshot.data() as Chat;
  },
};

interface TaskMessage {
  taskId: string;
  subTaskId?: string;
}

const reference = collection(db, "chats").withConverter(chatConverter);

export const ChatsProvider = ({ children }: PropsWithChildren) => {
  const { authUser } = useAuth();
  const { user } = useUser();
  const { organisation } = useOrganisation();
  const { log } = useAnalytics();

  const [deleteChatId, setDeleteChatId] = useState<string | undefined>();
  const [renameChatId, setRenameChatId] = useState<string | undefined>();

  const userChatsQuery = useMemo(() => {
    if (!authUser) return;

    return query(
      reference,
      where("userId", "==", authUser.uid),
      where("deleted", "==", false),
      orderBy("updatedAt", "desc"),
    );
  }, [authUser]);

  const [chats = [], loading] = useCollectionData(userChatsQuery);

  const createChat = useCallback(
    async (chat: Chat) => {
      const ref = doc(reference, chat.id);

      await setDoc(ref, chat);

      log({
        type: "chat_created",
        payload: {
          chatId: chat.id,
          organisationId: chat.organisationId,
          organisationName: organisation.name,
          messageLength: chat.messages[0]?.content?.length ?? 0,
        },
      });

      return chat.id;
    },
    [log, organisation.name],
  );

  const createTaskChatMessage = useCallback(
    async (message: string, { taskId }: TaskMessage) => {
      return createChat({
        id: uuid(),
        userId: authUser!.uid,
        deleted: false,
        taskId,
        files: {},
        messages: [
          {
            id: uuid(),
            role: Role.USER,
            content: message,
            errorMessage: null,
            model: null,
            platform: null,
            previousMessageId: null,
            updatedAt: new Date() as any,
            idempotencyKey: null,
            files: [],
          },
        ],
        updatedAt: serverTimestamp() as Timestamp,
        status: ChatStatus.REQUESTED,
        organisationId: user.organisationId ?? null,
        error: null,
      });
    },
    [authUser, createChat, user.organisationId],
  );

  const createChatFromMessage = useCallback(
    async (message: string, fileIds?: string[]) => {
      const files: Chat["files"] = {};

      if (fileIds?.length) {
        files[fileIds[0]] = {
          updatedAt: serverTimestamp() as Timestamp,
        };
      }

      return createChat({
        id: uuid(),
        userId: authUser!.uid,
        deleted: false,
        messages: [
          {
            id: uuid(),
            role: Role.USER,
            content: message,
            errorMessage: null,
            model: null,
            platform: null,
            previousMessageId: null,
            updatedAt: new Date() as any,
            idempotencyKey: null,
            files: fileIds || [],
          },
        ],
        files,
        updatedAt: serverTimestamp() as Timestamp,
        status: ChatStatus.REQUESTED,
        organisationId: user.organisationId ?? null,
        error: null,
      });
    },
    [authUser, createChat, user.organisationId],
  );

  const deleteChat = useCallback(
    async (id: string) => {
      const ref = doc(reference, id);

      await updateDoc(ref, "deleted", true);

      log({
        type: "chat_deleted",
        payload: {
          chatId: id,
        },
      });

      return id;
    },
    [log],
  );

  const renameChat = useCallback(
    async (id: string, title: string) => {
      const ref = doc(reference, id);
      await updateDoc(ref, "title", title);

      log({
        type: "chat_title_update",
        payload: {
          chatId: id,
          title,
        },
      });

      return id;
    },
    [log],
  );

  const chatsValue: ChatsContextProps = useMemo(
    () => ({
      loading,
      chats,
      reference,
      createChatFromMessage,
      deleteChat,
      deleteChatId,
      renameChatId,
      renameChat,
      setDeleteChatId,
      setRenameChatId,
      createTaskFromMessage: createTaskChatMessage,
    }),
    [
      chats,
      createChatFromMessage,
      createTaskChatMessage,
      deleteChat,
      deleteChatId,
      loading,
      renameChat,
      renameChatId,
    ],
  );

  return (
    <ChatsContext.Provider value={chatsValue}>{children}</ChatsContext.Provider>
  );
};
