import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

interface HeaderContextProps {
  visible: boolean;

  setVisible(visible: boolean): void;
}

export const HeaderContext = createContext<HeaderContextProps>(
  undefined as never,
);

export const useHeader = () => useContext(HeaderContext);

export const HeaderProvider = ({ children }: PropsWithChildren) => {
  const [visible, setVisible] = useState(false);

  const value = useMemo(() => {
    return {
      visible,
      setVisible,
    };
  }, [visible, setVisible]);

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};
