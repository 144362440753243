import React, { useEffect, useState } from "react";
import { WarningModal } from "ui";
import { LocaleStrings } from "~/locales";
import { Text } from "~/components/Text";
import { FileUploadErrorCode } from "./FileUploadModal";

interface FileUploadErrorProps {
  error?: FileUploadErrorCode;

  onClose(): void;

  onClear(): void;

  onNewChat(): void;
}

interface ErrorConfig {
  title: LocaleStrings;
  message: LocaleStrings;
  suggestion?: LocaleStrings;
  button: LocaleStrings;

  onClick(): void;
}

export function FileUploadError({
  error,
  onClear,
  onClose,
  onNewChat,
}: FileUploadErrorProps) {
  const [config, setConfig] = useState<ErrorConfig | undefined>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!error);
  }, [error]);

  useEffect(() => {
    if (!error) {
      return;
    }

    setConfig(() => {
      switch (error) {
        case "FILE_TYPE_UNSUPPORTED":
          return {
            title: "files.upload.error.invalid-file-type.title",
            message: "files.upload.error.invalid-file-type.message",
            suggestion: "files.upload.support-types.types",
            button: "files.upload.error.invalid-file-type.button-text",
            onClick: onClear,
          };
        case "FILE_COUNT_EXCEEDED":
          return {
            title: "files.upload.error.max-files-reached.title",
            message: "files.upload.error.max-files-reached.message",
            button: "files.upload.error.max-files-reached.button-text",
            onClick() {
              onNewChat();
              onClose();
            },
          };
        case "FILE_SIZE_EXCEEDED":
          return {
            title: "files.upload.error.file-size-exceeded.title",
            message: "files.upload.error.file-size-exceeded.message",
            button: "files.upload.error.file-size-exceeded.button-text",
            onClick: onClear,
          };
        case "FILE_PROCESSING_ERROR":
          return {
            title: "files.upload.error.file-processing-error.title",
            message: "files.upload.error.file-processing-error.message",
            button: "files.upload.error.file-processing-error.button-text",
            onClick: onClear,
          };
        case "FILE_CORRUPTED":
          return {
            title: "files.upload.error.file-corrupted.title",
            message: "files.upload.error.file-corrupted.message",
            button: "files.upload.error.file-corrupted.button-text",
            onClick: onClear,
          };
        default:
          return {
            title: "files.upload.error.upload-failed.title",
            message: "files.upload.error.upload-failed.message",
            button: "files.upload.error.upload-failed.button-text",
            onClick: onClear,
          };
      }
    });
  }, [error, onClear, onClose, onNewChat]);

  return (
    <WarningModal
      open={open}
      title={config?.title ?? ""}
      confirm={config?.button ?? ""}
      onConfirm={onClear}
      onClose={onClear}
    >
      {config && (
        <div
          className="text-center pb-4 prose prose-tight dark:prose-invert"
          data-file-error-type={error}
          data-testid="warning-modal-content"
        >
          <div className="text-content-secondary font-light">
            <Text id={config.message} multiline />

            {config.suggestion && (
              <p className="font-medium text-content-primary">
                <Text id={config.suggestion} />
              </p>
            )}
          </div>
        </div>
      )}
    </WarningModal>
  );
}
