import React from "react";

const PersonasDescription = () => {
  return (
    <div className="pt-24 flex w-full flex-col items-center">
      <h1 className="text-3xl text-content-secondary">User personas</h1>
      <p className="w-max[560px] pt-4 text-sm text-content-tertiary text-center">
        Et porro praesentium. Accusamus dolor consequatur ut hic quia enim iure
        modi rerum. <br /> Iste est ut ut earum qui perferendis voluptatem alias
        quidem.
      </p>
    </div>
  );
};

export default PersonasDescription;
