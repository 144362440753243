import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";

import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { File, FileStatus } from "@noa/types/File";
import { ref } from "firebase/storage";
import { db, setDoc } from "~/integrations/firebase/firestore";
import { useAuth } from "./AuthContext";
import { storage } from "~/integrations/firebase/storage";

interface FilesContextProps {
  files: File[];
  loading: boolean;
  error?: Error;
  reference: CollectionReference<File>;

  createFileForUploading(
    fileName: string,
    fileType: string,
  ): Promise<DocumentReference>;
}

export const FilesContext = createContext<FilesContextProps>(
  undefined as never,
);

export const useFiles = () => useContext(FilesContext);

export const FilesProvider = ({ children }: PropsWithChildren) => {
  const { authUser } = useAuth();

  const reference = useMemo(
    () => collection(db, "files") as CollectionReference<File>,
    [],
  );

  const [files = [], loading, error] = useCollectionData<File>(
    query(reference, where("userId", "==", authUser!.uid)),
  );

  const createFileForUploading = useCallback(
    async (fileName: string, fileType: string) => {
      const fileId = uuidv4();
      const fileReference = doc(reference, fileId);
      const storageReference = ref(
        storage,
        `users/${authUser!.uid}/${fileId}-${fileName}`,
      );

      await setDoc(fileReference, {
        fileName,
        fileType,
        id: fileId,
        status: FileStatus.UPLOADING,
        userId: authUser!.uid,
        updatedAt: serverTimestamp(),
        error: null,
        gcsPath: storageReference.fullPath,
        organisationId: null,
        idempotencyKey: null,
      });

      return fileReference;
    },
    [authUser, reference],
  );

  const value = useMemo(() => {
    return {
      files,
      loading,
      error,
      reference,
      createFileForUploading,
    };
  }, [files, loading, error, reference, createFileForUploading]);

  return (
    <FilesContext.Provider value={value}>{children}</FilesContext.Provider>
  );
};
